import { Role } from '@gm-commercial/profile-model';

import { AssetRemoteCommandExecuteStatus } from '~/common/models/asset.model';

export enum RemoteCommandType {
  ENGINE = 'engine',
  IGNITION = 'ignition',
  ALERT = 'alert',
  DOOR = 'door',
}

export enum RemoteCommandAction {
  LOCK = 'lock',
  UNLOCK = 'unlock',
  START = 'start',
  CANCEL = 'cancel',
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export enum RemoteCommand {
  DOOR_LOCK = 'door_lock',
  DOOR_UNLOCK = 'door_unlock',
  ALERT_START = 'alert_start',
  ALERT_CANCEL = 'alert_cancel',
  ENGINE_START = 'engine_start',
  ENGINE_CANCEL = 'engine_cancel',
  IGNITION_ENABLE = 'ignition_enable',
  IGNITION_DISABLE = 'ignition_disable',
}

export enum AssetRemoteCommandRequestType {
  EXECUTE_DOOR_LOCK = 'Execute Door Lock Command',
  EXECUTE_DOOR_UNLOCK = 'Execute Door Unlock Command',
  EXECUTE_START_ALERT = 'Execute Start Alert Command',
  EXECUTE_CANCEL_ALERT = 'Execute Cancel Alert Command',
  EXECUTE_START_ENGINE = 'Execute Start Engine Command',
  EXECUTE_CANCEL_ENGINE = 'Execute Cancel Engine Command',
  EXECUTE_ENABLE_IGNITION = 'Execute Enable Ignition Command',
  EXECUTE_DISABLE_IGNITION = 'Execute Disable Ignition Command',
}

export enum RemoteCommandStatus {
  DEFAULT = 'default',
  PENDING = 'pending',
  DISABLED = 'disabled',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const REMOTE_COMMANDS_EXCLUDED_ROLES = [
  Role.SYSTEM_READER,
  Role.SYSTEM_MANAGER,
];

export interface RemoteEnableDisableModalProps {
  assetId: string;
  command: RemoteCommand;
  commandType: RemoteCommandType;
  isTCPSAccepted: boolean;
  location: number[];
  vehicleName?: string;
  ignitionStatus?: RemoteCommandIgnitionStatus;
  triggerSnackbar?: (status: AssetRemoteCommandExecuteStatus) => void;
  lastCommunicated?: string;
}

export enum RemoteCommandIgnitionStatus {
  OFF = 'off',
  DISABLED = 'disabled',
  ON = 'on',
}

export enum RemoteCommandGearMode {
  PARK = 'P',
  REVERSE = 'R',
  NEUTRAL = 'N',
  DRIVE = 'D',
}

export enum EnableDisableStep {
  SELECTION,
  MAP,
  DISCLAIMER,
  CONFIRM,
}
