import {
  DEFAULT_SESSION_CONFIG_KEY,
  SearchType,
} from '~/common/constants/common.constant';
import { PERIPHERAL_FILTER_EMPTY_STATE } from '~/features/peripherals/state/PeripheralFilter.model';

import { peripheralsAdapter } from '../../state/peripheralsSlice.adapters';
import {
  PeripheralsSessionViewType,
  PeripheralsState,
} from '../../state/peripheralsSlice.model';

export const INITIAL_PERIPHERALS_STATE: PeripheralsState = {
  items: peripheralsAdapter.getInitialState(),
  sessionConfigs: {
    [PeripheralsSessionViewType.LIST_VIEW]: {
      [DEFAULT_SESSION_CONFIG_KEY]: {
        selectedIds: [],
        selectedItems: [],
        peripherals: peripheralsAdapter.getInitialState(),
        allSelected: false,
        filterType: PERIPHERAL_FILTER_EMPTY_STATE,
        filterModalOpen: false,
        searchCriteria: {
          input: '',
          searchType: SearchType.VIN,
        },
      },
    },
  },
  operations: {},
};

export enum PeripheralVehiclesConfig {
  LIST_VIEW = 'list view',
}
