import { Box } from '@mui/material';

import LoadingIcon from '~/assets/icons/new/loading-02.svg?react';

import { useStyles } from './Spinner.styles';

type Props = {
  show?: boolean;
};

function Spinner({ show = false }: Props) {
  const { classes } = useStyles();

  if (!show) {
    return null;
  }

  return (
    <Box className={classes['common--cmp--box-spinner']}>
      <LoadingIcon />
    </Box>
  );
}

export default Spinner;
