import {
  AlertCategory,
  AlertEvent,
  AlertQueryFieldType,
} from '~/common/models/alert.model';
import { BDQueryCriteriaType } from '~/common/models/query.model';

export const USER_ACTIVITY_QUERY = {
  qualifier: BDQueryCriteriaType.IS_EQUAL,
  field: AlertQueryFieldType.CATEGORY,
  values: [AlertCategory.PROFILE] as unknown as object[],
};

export const PROFILE_SUBSCRIPTION_QUERY = {
  qualifier: BDQueryCriteriaType.ARRAY_CONTAINS,
  field: AlertQueryFieldType.REGISTRATIONS,
  values: [{ category: AlertCategory.PROFILE }],
};

export const ACCOUNT_ACTIVITY_QUERY = {
  qualifier: BDQueryCriteriaType.IS_EQUAL,
  field: AlertQueryFieldType.CATEGORY,
  values: [AlertCategory.PROFILE] as unknown as object[],
};

export const VEHICLE_ACTIVITY_QUERY = {
  qualifier: BDQueryCriteriaType.IS_EQUAL,
  field: AlertQueryFieldType.CATEGORY,
  values: [AlertCategory.ASSET] as unknown as object[],
};

export const VEHICLE_DIAGNOSTICS_QUERY = {
  qualifier: BDQueryCriteriaType.IS_EQUAL,
  field: AlertQueryFieldType.CATEGORY,
  values: [AlertCategory.DIAGNOSTIC] as unknown as object[],
};

export enum NotificationSettingsConfigType {
  ADD_VIEW = 'Add View',
}

export const VEHICLE_DIAGNOSTICS_SUBSCRIPTION_QUERY = {
  qualifier: BDQueryCriteriaType.ARRAY_CONTAINS,
  field: AlertQueryFieldType.REGISTRATIONS,
  values: [{ category: AlertCategory.DIAGNOSTIC }],
};

export const VEHICLE_ACTIVITY_SUBSCRIPTION_QUERY = {
  qualifier: BDQueryCriteriaType.ARRAY_CONTAINS,
  field: AlertQueryFieldType.REGISTRATIONS,
  values: [{ category: AlertCategory.ASSET }],
};

export const ACCOUNT_ACTIVITY_ALERT_EVENTS = [AlertEvent.ACCOUNT_UPDATE];

export const USER_ACTIVITY_ALERT_EVENTS = [
  AlertEvent.ADD_USER,
  AlertEvent.REMOVE_USER,
];
