import { VehicleDetailsType } from './asset-report.model';
import { Entity, NonNullablePick } from './common.model';

export enum CameraStatus {
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
}

export enum PeripheralLastCommunicatedStatus {
  LESS_THAN_WEEK = 'less_than_week',
  MORE_THAN_WEEK = 'more_than_week',
}

export enum CameraHealth {
  HEALTHY = 'healthy',
  UNHEALTHY = 'unhealthy',
  UNKNOWN = 'unknown',
}

export enum PeripheralType {
  FLEETCAMERA = 'fleet-camera',
  FLEETKEYMODULE = 'fleet-key-module',
}

export enum PeripheralMake {
  LYTX = 'LYTX', // cameras
  VOXX = 'VOXX', // fleet-key
  VAST = 'VAST', // fleet-key
}

export enum PeripheralModel {
  AI14 = 'AI-14', // LYTX
  VSM22 = 'VSM2.2', // VOXX
  VSM30 = 'VSM3.0', // VAST
}

export interface AttachedToVinData {
  vin: string;
  name?: string;
  licensePlate?: string;
  vehicleType?: VehicleDetailsType;
}

export interface HealthData {
  value: CameraHealth;
  lastUpdateDateTime: string;
}

export interface StatusData {
  value: CameraStatus;
  lastUpdateDateTime: string;
}

export interface PeripheralListItem extends Entity {
  imei: string;
  fleetId?: string;
  organizationId?: string;
  hubId?: string;
  peripheralType?: PeripheralType;
  make?: PeripheralMake;
  model?: string;
  attachedToVin?: AttachedToVinData;
  status?: StatusData;
  health?: HealthData | null;
  lastCommunicationDateTime?: string;
  firmware?: string;
  creationDateTime?: string;
}

export type PeripheralOnboardFormFields = {
  csvFile: File;
  organizationsId: string;
  peripheralType: PeripheralType;
};

export type ValidPeripheralOnboardForm = NonNullablePick<
  PeripheralOnboardFormFields,
  'organizationsId' | 'peripheralType'
> &
  Pick<PeripheralOnboardFormFields, 'csvFile'>;

export enum PeripheralOnboardStatusValue {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface PeripheralsOnboardApiResponse {
  batchId: string;
  organizationId: string;
}

export interface PeripheralsOnboardResponse {
  batchId: string;
  organizationId: string;
}

interface PeripheralOnboard {
  inputId: string;
  errorId: string;
}

export interface PeripheralsOnboardDetailsApiResponse {
  totalItems: number;
  successCount: number;
  failureCount: number;
  errors: PeripheralOnboard[];
}
export interface PeripheralOnboardStatusApiResponse {
  id: string;
  status: PeripheralOnboardStatusValue;
  error: string;
  parameters?: {
    organizationId: string;
    peripheralType: PeripheralType;
    make: PeripheralMake;
    model: string;
    version: string;
    fileName: string;
  };
  details: PeripheralsOnboardDetailsApiResponse;
}

export interface PeripheralOnboardStatusResponse {
  id: string;
  status: PeripheralOnboardStatusValue;
  error: string;
  parameters: {
    organizationId: string | undefined;
    peripheralType: PeripheralType | undefined;
    make: PeripheralMake | undefined;
    model: string | undefined;
    version: string | undefined;
    fileName: string | undefined;
  };
  details: PeripheralsOnboardDetailsApiResponse;
}

export interface PeripheralMoveFleetSummary {
  assignedSuccessPeripherals?: string[];
  assignedFailedPeripherals?: {
    id: string;
    error: {
      id: string;
      message: string;
    };
  }[];
}

export interface ApiPeripheralMoveFleetSummary {
  successList: string[];
  failedList: {
    id: string;
    error: {
      id: string;
      message: string;
    };
  }[];
}
