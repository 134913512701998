import { Address } from '@gm-commercial/location-model';

import { Role } from './role.model';

export enum InvitationStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export type Invite = {
  status?: string;
  acceptedDate?: string;
  plannedExpiredDate?: string;
  lastExpiredDate?: string;
  lastInvitedBy?: string;
  lastInvitedDate?: string;
  lastResentBy?: string;
  lastResentDate?: string;
  lastCanceledBy?: string;
  lastCanceledDate?: string;
};

//TODO:Remove during integration
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  role: Role;
  email: string;
  /**
   * @deprecated name is redundant with displayName, which is closer to what the API returns, and will avoid an extra mapping.
   * Removal of this property in progress, please do not use in additional places.
   */
  name: string;
  displayName: string;
  status: ActivationStatus;
  phoneNumber?: string;
  address?: Address;
  organization?: UserScopeEntity;
  hub?: UserScopeEntity;
  fleet?: UserScopeEntity;
  creationDate?: string;
  activationDate?: string;
  userPreferences?: {
    locale?: UserSupportedLocale;
    timeZone?: string;
    doNotShowLegalNotice?: boolean;
  };
  lastSignedInDate?: string;
  invite?: Invite;
}

export enum ActivationStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DISABLED = 'disabled',
}

export enum UserSupportedLocale {
  en_US = 'en-US',
  en_CA = 'en-CA',
  fr_CA = 'fr-CA',
  es_MX = 'es-MX',
}

type UserScopeEntity = {
  id: string;
  name: string;
};
export enum UserTabType {
  ORGANIZATION = 'organization',
  GM = 'gm',
}
export const SUPPORTED_USER_TAB = [UserTabType.ORGANIZATION, UserTabType.GM];
