import { BDPageSpinner } from '@brightdrop/bd-ui';
import {
  useFeatureFlags,
  useFeatureFlagsProviderContext,
} from '@brightdrop/feature-flags-client';
import { TelemetryProviderContext } from '@brightdrop/telemetry-client';
import { InvitationStatus, Role, User } from '@gm-commercial/profile-model';
import { Box } from '@mui/material';
import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ROLE_KEYS } from '~/common/constants/common.constant';
import useActingProfile from '~/common/hooks/useActingProfile';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import usePageTitle from '~/common/hooks/usePageTitle';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
} from '~/common/models/apis/apiResponse.model';
import { ActivationStatus } from '~/common/models/common.model';
import { BDError } from '~/common/models/error.model';
import { AppRoutePaths } from '~/common/models/route.model';
import { getDefaultRoutePath } from '~/common/utils/route/route.utils';

import { useAppDispatch } from '../../../app/store';
import { setEffectiveFeatures } from '../../featureFlags/featureFlagsSlice';
import { getOrganizationDetails } from '../../organizations/organizationsSlice';
import { makeSelectOrgOperationByType } from '../../organizations/organizationsSlice.selectors';
import {
  getAllowedContexts,
  getPermissions,
  getProfile,
  ProfileRequestType,
  resetProfile,
  setActingProfile,
} from '../../profile/profileSlice';
import {
  makeProfileOperationSelector,
  selectActingProfile,
  selectPermissionsContextIds,
  selectProfileContextIds,
  selectProfileFleet,
  selectProfileHub,
  selectProfileId,
  selectProfileInvite,
  selectProfileOrganization,
  selectProfileStatus,
  selectRole,
} from '../../profile/profileSlice.selectors';
import { selectAuthB2CTokenExpiration } from '../authSlice.selectors';
import { buildRedirectPathQuery } from '../utils/location.utils';

export enum BDLoginError {
  NO_PROFILE = 'no-profile',
  SERVICE_ERROR = 'service-unavailable',
}

const CONTEXT_EXCLUSIONS = [Role.SYSTEM_MANAGER];

const USER_CONTEXT = 'user';

const selectProfileParams = createSelector(
  selectProfileId,
  selectRole,
  selectProfileStatus,
  selectProfileOrganization,
  selectProfileHub,
  selectProfileFleet,
  selectProfileInvite,
  (id, role, status, organization, hub, fleet, invite) => {
    return { id, role, status, organization, hub, fleet, invite };
  }
);

const selectGetProfileOperation = makeProfileOperationSelector(
  ProfileRequestType.GET_PROFILE
);
const selectGetPermissionsOperation = makeProfileOperationSelector(
  ProfileRequestType.GET_PERMISSIONS
);
const selectGetOrgDetailsOperation = makeSelectOrgOperationByType(
  BDRequestType.GET_BY_ID
);

const PAGE_NAME = 'Login Inbound';

const InboundRedirect = (): JSX.Element => {
  usePageTitle(PAGE_NAME);
  const dispatch = useAppDispatch();
  const { token, authenticationType } = useCustomAuthentication();

  const history = useHistory();
  const location = useLocation<Location>();
  const { addTelemetryContext } = useContext(TelemetryProviderContext);
  const { getFlag, featureFlags } = useFeatureFlags();
  const { ready: areFeatureFlagReady, setContext: setFeatureFlagsContext } =
    useFeatureFlagsProviderContext();

  const b2cExpiration = useSelector(selectAuthB2CTokenExpiration);
  const profileParams = useSelector(selectProfileParams); // use login role
  const profileContextIds = useSelector(selectProfileContextIds);
  const permissionContextIds = useSelector(selectPermissionsContextIds);
  const { status: profileRequestStatus, errors: profileRequestErrors } =
    useSelector(selectGetProfileOperation);
  const { status: permissionsRequestStatus, errors: permissionsRequestErrors } =
    useSelector(selectGetPermissionsOperation);

  const { status: organizationRequestStatus } = useSelector(
    selectGetOrgDetailsOperation
  );

  const profile = useSelector(selectActingProfile);
  const { isActing } = useActingProfile();
  const role = useMemo(
    () => (profile?.role ? ROLE_KEYS[profile.role] : undefined),
    [profile?.role]
  );
  const scopeId = useMemo(
    () => profile?.fleet?.id || profile?.hub?.id || profile?.organization?.id,
    [profile?.fleet?.id, profile?.hub?.id, profile?.organization?.id]
  );
  //User key that works for impersonation
  const userKey = useMemo(
    () =>
      isActing && profile?.id && role && scopeId
        ? `${profile.id}-${String(role)}-${scopeId}`
        : profile?.id,
    [isActing, profile?.id, role, scopeId]
  );

  const [organizationReady, setOrganizationReady] = useState(false);
  const [loginError, setLoginError] = useState<BDLoginError | null>(null);

  const handleErrors = useCallback((errors?: BDError<unknown>[]) => {
    if (Array.isArray(errors) && errors.length > 0) {
      const errorStatus = errors[0]?.status;
      if (errorStatus && errorStatus === 404) {
        setLoginError(BDLoginError.NO_PROFILE);
      } else {
        setLoginError(BDLoginError.SERVICE_ERROR);
      }
    }
  }, []);

  const redirectQuery = useMemo(
    () => buildRedirectPathQuery(location.state),
    [location.state]
  );

  const redirectToLogout = useCallback(() => {
    history.push({
      pathname: `/auth/${authenticationType}/logout`,
      search: redirectQuery,
    });
  }, [authenticationType, history, redirectQuery]);

  useEffect(() => {
    if (b2cExpiration) {
      const diff = DateTime.fromMillis(b2cExpiration)
        //The expiration returned by b2c as expiresOn is not the real expiration and is forcing users to logout when closing and opening the tab while the token is not expired
        //The value below needs to be adjusted if b2c expiration is changed
        .diff(DateTime.now().minus({ days: 1 }))
        .toMillis();
      if (diff <= 0) {
        redirectToLogout();
      }
    }
  }, [b2cExpiration, redirectToLogout]);

  useEffect(() => {
    dispatch(resetProfile());
  }, [dispatch]);

  useEffect(() => {
    handleErrors(profileRequestErrors);
  }, [handleErrors, profileRequestErrors]);

  useEffect(() => {
    handleErrors(permissionsRequestErrors);
  }, [handleErrors, permissionsRequestErrors]);

  useEffect(() => {
    if (profileParams.id) {
      addTelemetryContext('user', {
        id: profileParams.id,
        role: profileParams.role,
        organization: profileParams.organization,
        hub: profileParams.hub,
        fleet: profileParams.fleet,
      });
    }
  }, [addTelemetryContext, profileParams]);

  useEffect(() => {
    if (profileRequestStatus !== BDRequestStatus.SUCCEEDED) {
      return;
    }
    if (
      profileParams?.role === Role.ORG_MANAGER &&
      profileParams?.status === ActivationStatus.ACTIVE &&
      profileContextIds?.organizationsId
    ) {
      dispatch(
        getOrganizationDetails({
          organizationsId: profileContextIds?.organizationsId,
        })
      );
    } else {
      setOrganizationReady(true);
    }
  }, [
    profileRequestStatus,
    profileParams?.role,
    profileParams?.status,
    profileContextIds?.organizationsId,
    dispatch,
  ]);

  useEffect(() => {
    setOrganizationReady(
      (previousValue) =>
        previousValue || organizationRequestStatus === BDRequestStatus.SUCCEEDED
    );
  }, [organizationRequestStatus]);

  useEffect(() => {
    if (
      !!token &&
      !profileParams.id &&
      (!profileRequestStatus || profileRequestStatus === BDRequestStatus.IDLE)
    ) {
      dispatch(getProfile()).then((result) => {
        if (result.meta.requestStatus === 'rejected') {
          const errorResult = result as PayloadAction<
            BDError<ApiResponse<User>>
          >;
          if (errorResult?.payload?.status === 401) {
            redirectToLogout();
          }
        }
      });
    }
  }, [
    profileParams.id,
    profileRequestStatus,
    dispatch,
    redirectToLogout,
    token,
  ]);

  useEffect(() => {
    if (profile?.id && window.heap?.getIdentity() === null) {
      /* Identify user for analytics purposes if not already identified */
      const emailDomain = profile.email.match(/(?<=@).*/);

      window.heap.identify(profile.id);
      window.heap.addUserProperties({
        emailDomain: emailDomain ? emailDomain[0] : 'Unknown',
        orgId: profile.organization ? profile.organization.id : 'Unknown',
        orgName: profile.organization ? profile.organization.name : 'Unknown',
        role: profile.role ? profile.role : 'Unknown',
      });
    }
  }, [profile, window?.heap]);

  useEffect(() => {
    if (
      profileParams.id &&
      profileRequestStatus === BDRequestStatus.SUCCEEDED &&
      (!permissionsRequestStatus ||
        permissionsRequestStatus === BDRequestStatus.IDLE)
    ) {
      dispatch(getPermissions()).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          dispatch(setActingProfile());
        }
      });
    }
  }, [
    profileParams.id,
    profileRequestStatus,
    permissionsRequestStatus,
    dispatch,
  ]);

  useEffect(() => {
    const { organizationsId, hubsId, fleetsId } = profileContextIds || {};
    if (
      profileParams.role &&
      !CONTEXT_EXCLUSIONS.includes(profileParams.role) &&
      organizationsId
    ) {
      dispatch(
        getAllowedContexts({
          organizationsId,
          hubsId,
          fleetsId,
        })
      );
    }
  }, [dispatch, profileParams.role, profileContextIds]);
  useEffect(() => {
    //Only want to redirect to terms and conditions if the user's status is pending
    //and the invite is not in a cancelled or expired state
    //Note: Users without an invitation typically are legacy users that have been whitelisted
    if (
      profileParams.status === ActivationStatus.PENDING &&
      profileRequestStatus === BDRequestStatus.SUCCEEDED &&
      permissionsRequestStatus === BDRequestStatus.SUCCEEDED
    ) {
      //TODO: Update endpoint to be new /invalid-invite page to be created later
      //Need to redirect users to an error page for cancelled and expired invitations
      if (
        profileParams.invite?.status === InvitationStatus.CANCELLED ||
        profileParams.invite?.status === InvitationStatus.EXPIRED
      ) {
        const path = AppRoutePaths.SERVICE_UNAVAILABLE;
        history.push(path);
      } else {
        const path = `/users/${profileParams?.id}/terms-and-conditions`;
        history.push(path);
      }
    }
  }, [
    history,
    permissionsRequestStatus,
    profileParams?.id,
    profileParams.invite?.status,
    profileParams.status,
    profileRequestStatus,
  ]);

  useEffect(() => {
    if (
      profileParams.status === ActivationStatus.ACTIVE &&
      profileRequestStatus === BDRequestStatus.SUCCEEDED &&
      permissionsRequestStatus === BDRequestStatus.SUCCEEDED &&
      areFeatureFlagReady &&
      organizationReady
    ) {
      if (location.state?.pathname && location.state.pathname !== '/') {
        history.replace(location.state.pathname + location.state?.search);
        return;
      }
      const defaultPath = getDefaultRoutePath(
        profileParams?.role,
        permissionContextIds || {},
        getFlag
      );
      history.push(defaultPath);
    }
  }, [
    profileRequestStatus,
    permissionsRequestStatus,
    location,
    history,
    profileParams,
    permissionContextIds,
    organizationReady,
    getFlag,
    areFeatureFlagReady,
  ]);

  /*
  TODO: Remove this effect and its slice once '34205-Charging-Schedule-Config' is removed.
  It is the only feature flag that is used deep in the state
  */
  useEffect(() => {
    if (featureFlags) {
      dispatch(setEffectiveFeatures(featureFlags));
    }
  }, [dispatch, featureFlags]);

  useEffect(() => {
    if (userKey) {
      setFeatureFlagsContext(USER_CONTEXT, {
        kind: USER_CONTEXT,
        key: userKey,
        role,
        organizationId: profile?.organization?.id,
        hubId: profile?.hub?.id,
        fleetId: profile?.fleet?.id,
        language: profile?.userPreferences?.locale,
      });
    }
  }, [
    role,
    profile?.organization?.id,
    profile?.hub?.id,
    profile?.fleet?.id,
    profile?.userPreferences?.locale,
    userKey,
    setFeatureFlagsContext,
  ]);

  useEffect(() => {
    if (loginError) {
      switch (loginError) {
        case BDLoginError.NO_PROFILE:
          history.push(AppRoutePaths.BUSINESS_ENROLLMENT);
          break;
        case BDLoginError.SERVICE_ERROR:
          history.push(AppRoutePaths.SERVICE_UNAVAILABLE);
          break;
        default:
          // TODO: some default case for "unknown" errors -
          break;
      }
    }
  }, [history, loginError]);

  useEffect(() => {
    if (!token) {
      history.push({
        pathname: `/auth/${authenticationType}`,
        search: redirectQuery,
      });
    }
  }, [authenticationType, history, redirectQuery, token]);

  return (
    <Box data-testid="loading" m={0}>
      <BDPageSpinner />
    </Box>
  );
};

export default InboundRedirect;
