import { AppRoutePaths } from '~/common/models/route.model';

export const MESSAGES = {
  'common:filterKeywords': 'FILTER KEYWORDS',
  'common:alertMsg.messageView.pageNotFound.header': 'Page not found',
  'common:alertMsg.messageView.pageNotFound.button': 'Return home',
  'common:alertMsg.messageView.unauthorized.header': 'Missing Permissions',
  'common:alertMsg.messageView.unauthorized.body':
    'Please contact an account administrator for access.',
  'common:alertMsg.messageView.unauthorized.button': 'Return home',
  'common:alertMsg.messageView.noBusinessFound.header': 'No business found',
  'common:alertMsg.messageView.noBusinessFound.body':
    'Your GM account is not associated with a business. Please contact an account administrator.',
  'common:alertMsg.messageView.serviceUnavailable.header':
    'Temporarily Unavailable',
  'common:alertMsg.messageView.serviceUnavailable.body': `This page isn't available right now. Please try again later.`,
  'common:alertMsg.messageView.serviceUnavailable.button': 'Return home',

  'common:alertMsg.messageView.mobileAppRequired.header': 'Mobile App Required',
  'common:alertMsg.messageView.mobileAppRequired.body':
    'BrightDrop Operator may only access the system through the BrightDrop mobile app.  Please contact your manager to request access to the BrightDrop mobile application.',
};

export const WarningExcludedPaths = [
  AppRoutePaths.TERMS_CONDITIONS,
  AppRoutePaths.BUSINESS_ENROLLMENT,
  AppRoutePaths.PAGE_NOT_FOUND,
];
