import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useTranslations } from '@brightdrop/localization-client';
import { cx } from '@emotion/css';
import { usePermissions, useRole } from '@gm-commercial/profile-context';
import {
  AlertPermission,
  ProfilePermission,
  Role,
} from '@gm-commercial/profile-model';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';

import { SecureWrapper } from '~/common/components';
import useActingProfile from '~/common/hooks/useActingProfile';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import { CUSTOMER_SUPPORT_FLAG_NAME } from '~/common/models/featureFlags.model';
import { NOTIFICATIONS_REQUIRED_ROLES } from '~/common/models/notification.model';
import { ACCOUNT_PROFILE_PERSONAL_PATH } from '~/common/models/pages/accountPages.model';
import { SETTINGS_NOTIFICATIONS_VEHICLES } from '~/common/models/pages/settingsPages.model';
import { SUPPORT_CONTACT_PATH } from '~/common/models/pages/supportPages.model';
import { AppRoutePaths } from '~/common/models/route.model';
import {
  getNavDisabledPaths,
  getProfileDisabled,
} from '~/common/utils/route/route.utils';

import VersionRefresher from '../../app/versionRefresher/VersionRefresher';
import {
  NAV_DRAWER_CLOSED_WIDTH,
  NAV_DRAWER_OPEN_WIDTH,
} from '../navigationDrawer/GlobalNavDrawer.styles';
import {
  selectActingPermissionsContextIds,
  selectActingProfileFirstName,
  selectActingProfileId,
  selectActingProfileLastName,
  selectActingProfileOrganization,
  selectActingProfileStatus,
  selectActingRole,
} from '../profile/profileSlice.selectors';
import GlobalSearch from './components/GlobalSearch/GlobalSearch';
import NotificationBadge from './components/NotificationBadge/NotificationBadge';
import ProfileMenu from './components/ProfileMenu/ProfileMenu';
import { getDefaultProfileMenu } from './components/ProfileMenu/ProfileMenu.config';
import { VIEW_AS_BANNER_HEIGHT } from './components/ViewAsHeader/ViewAsHeader.styles';
import useStyles from './GlobalHeader.styles';

const MESSAGES = {
  'common:header.openUserMenu': 'Open user menu',
  'common:header.profileMenu': 'Profile menu',
  'common:header.logout': 'Logout',
  'common:header.support': 'Support',
  'common:navDrawer.openNav': 'Open navigation drawer',
  'common:account': 'Account',
  'common:settings': 'Settings',
  roleList: {
    [`common:roleList.${Role.ORG_MANAGER}`]: 'Account administrator',
    [`common:roleList.${Role.HUB_MANAGER}`]: 'Hub Manager',
    [`common:roleList.${Role.FLEET_MANAGER}`]: 'Fleet Manager',
    [`common:roleList.${Role.OPERATOR}`]: 'Operator',
    [`common:roleList.${Role.SYSTEM_MANAGER}`]: 'Advisor',
    [`common:roleList.${Role.SERVICE_TECHNICIAN}`]: 'Service Technician',
    [`common:roleList.${Role.SYSTEM_READER}`]: 'System Reader',
    [`common:roleList.${Role.POC_GROCERYMANAGER}`]: 'Grocery Manager',
    [`common:roleList.${Role.SERVICE_MANAGER}`]: 'Service Manager',
  },
};

const selectActingProfileParams = createSelector(
  selectActingProfileId,
  selectActingProfileFirstName,
  selectActingProfileLastName,
  selectActingRole,
  selectActingProfileStatus,
  selectActingProfileOrganization,
  selectActingPermissionsContextIds,
  (usersId, firstName, lastName, role, status, organization, contextIds) => {
    return {
      usersId,
      firstName,
      lastName,
      role,
      status,
      organization,
      ...contextIds,
    };
  }
);

interface StyledAppBarProps extends AppBarProps {
  open?: boolean;
  isActing?: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isActing',
})<StyledAppBarProps>(({ theme, open, isActing }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - (${NAV_DRAWER_CLOSED_WIDTH}px))`,
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: `${NAV_DRAWER_OPEN_WIDTH}px`,
      width: `calc(100% - ${NAV_DRAWER_OPEN_WIDTH}px)`,
    },
  }),
  ...(isActing && {
    top: `${VIEW_AS_BANNER_HEIGHT}px`,
  }),
}));

const GlobalHeader = ({ navExpanded }: { navExpanded: boolean }) => {
  const { translations } = useTranslations(MESSAGES);
  const actingProfileParams = useSelector(selectActingProfileParams);
  const { isActing } = useActingProfile();
  const { authenticationType } = useCustomAuthentication();

  const menuId = 'account-menu';
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { hasRole } = useRole();
  const { hasPermission } = usePermissions();
  const location = useLocation();

  const matchSessionRoute = matchPath(location.pathname, {
    path: [AppRoutePaths.SERVICE_UNAVAILABLE],
  });
  const matchUnsupportedRoute = matchPath(location.pathname, {
    path: getNavDisabledPaths(),
  });
  const suppressNavigation = useMemo(
    () => !!matchUnsupportedRoute,
    [matchUnsupportedRoute]
  );
  const unSpportedRoute = matchPath(location.pathname, {
    path: getProfileDisabled(),
  });
  const canUpdateProfile = useMemo(
    () => hasPermission([ProfilePermission.UPDATE]),
    [hasPermission]
  );

  const disableProfile = useMemo(() => !!unSpportedRoute, [unSpportedRoute]);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // 'Support' feature flag
  const { getFlag } = useFeatureFlags();
  const isCustomerSupportFlagEnabled = getFlag(CUSTOMER_SUPPORT_FLAG_NAME);

  const updatedProfileMenu = useMemo(() => {
    const { role, usersId } = actingProfileParams;
    const defaultProfileMenu = getDefaultProfileMenu(authenticationType);
    const isNotificationAllowed = hasRole(NOTIFICATIONS_REQUIRED_ROLES);
    const updatedProfileMenu =
      usersId &&
      role !== Role.OPERATOR &&
      !suppressNavigation &&
      !disableProfile &&
      canUpdateProfile
        ? [
            ...[
              {
                label: 'common:account',
                testId: 'account-menu',
                onClick: {
                  redirect: ACCOUNT_PROFILE_PERSONAL_PATH,
                },
                section: 'top',
              },
            ],
            ...(isNotificationAllowed
              ? [
                  {
                    label: 'common:settings',
                    testId: 'settings-menu',
                    onClick: {
                      redirect: SETTINGS_NOTIFICATIONS_VEHICLES,
                    },
                    section: 'top',
                  },
                ]
              : []),
            ...(isCustomerSupportFlagEnabled
              ? [
                  {
                    label: 'common:header.support',
                    testId: 'support-menu',
                    onClick: {
                      redirect: SUPPORT_CONTACT_PATH,
                    },
                    section: 'top',
                  },
                ]
              : []),
            ...[],
            ...defaultProfileMenu,
          ]
        : defaultProfileMenu;

    return updatedProfileMenu;
  }, [
    actingProfileParams,
    hasRole,
    suppressNavigation,
    disableProfile,
    canUpdateProfile,
    authenticationType,
    isCustomerSupportFlagEnabled,
  ]);

  return (
    <>
      <VersionRefresher />
      <StyledAppBar
        className={cx(classes.headerWrapper, {
          hideBar: suppressNavigation,
        })}
        position="fixed"
        open={navExpanded}
        isActing={isActing}
      >
        <Box className={classes.container}>
          <Toolbar className={classes.header}>
            <SecureWrapper requiredPermissions={[]} passThrough>
              <GlobalSearch />
            </SecureWrapper>
            <SecureWrapper
              requiredPermissions={[AlertPermission.READ]}
              requiredRoles={NOTIFICATIONS_REQUIRED_ROLES}
            >
              <NotificationBadge />
            </SecureWrapper>
            <SecureWrapper
              requiredPermissions={[]}
              passThrough={!!matchSessionRoute}
            >
              <div>
                <Button
                  id="user-menu-btn"
                  aria-label={translations['common:header.openUserMenu']}
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="primary"
                  onClick={handleMenu}
                  className={classes.profileContainerButton}
                >
                  <div
                    className={cx(classes.profileContainer, {
                      isOpen: !!anchorEl,
                    })}
                    data-app-version={globalThis?.appConfig?.version}
                  >
                    {actingProfileParams?.firstName?.charAt(0)}
                    {actingProfileParams?.lastName?.charAt(0)}
                  </div>
                </Button>
                <ProfileMenu
                  label={translations['common:header.profileMenu']}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  items={updatedProfileMenu.map(
                    ({ label, testId, onClick, section }) => ({
                      label:
                        translations[
                          label as keyof Omit<
                            typeof translations,
                            | 'roleList'
                            | 'settingsMenu'
                            | 'accountMenu'
                            | 'fleetMenu'
                            | 'legacyMenu'
                          >
                        ],
                      testId,
                      onClick: { redirect: onClick?.redirect },
                      section: section,
                    })
                  )}
                />
              </div>
            </SecureWrapper>
          </Toolbar>
        </Box>
      </StyledAppBar>
    </>
  );
};

export default GlobalHeader;
