import { Link } from '@mui/material';
import { DateTime } from 'luxon';
import { Trans } from 'react-i18next';

import { AlertDto } from '~/api/alerts/generated-api';
import {
  SearchType,
  TIRES_THRESHOLD_FLAT,
  TIRES_THRESHOLD_LOW,
} from '~/common/constants/common.constant';
import {
  AlertDetailsMap,
  AlertEntitiesQueryFieldType,
  AlertEvent,
  AlertQueryFieldType,
  AlertType,
  AlertTypeFilter,
  ReceiptPreferenceType,
} from '~/common/models/alert.model';
import { ApiAssetType } from '~/common/models/asset.model';
import {
  DateRange as AlertDateRange,
  SearchCriteria,
} from '~/common/models/common.model';
import { FLEET_VEHICLE_LIST_DETAILS } from '~/common/models/pages/fleetPages.model';
import { BDQuery, BDQueryCriteriaType } from '~/common/models/query.model';
import { getDateRangeConfig } from '~/common/utils/date-time.utils';
import { buildQueryTree } from '~/common/utils/query.utils';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

import { AlertListFilterSession, AlertParams } from '../alertsSlice';

const buildAssetsUrl = (alert_objectid: string) => {
  return FLEET_VEHICLE_LIST_DETAILS + `?assetsId=${alert_objectid}`;
};

export const createSearchQuery = (
  {
    nameFieldName,
    idFieldName,
  }: { nameFieldName: string; idFieldName: string },
  searchType: SearchType,
  input: string
): BDQuery | undefined => {
  let criteria;
  switch (searchType) {
    case SearchType.ASSET: {
      criteria = {
        qualifier: BDQueryCriteriaType.STARTS_WITH,
        field: nameFieldName,
        values: [input],
      } as BDQuery;
      break;
    }
    case SearchType.ID: {
      criteria = {
        qualifier: BDQueryCriteriaType.STARTS_WITH,
        field: idFieldName,
        values: [input],
      } as BDQuery;
      break;
    }
  }
  return criteria;
};

export const mapAlertTypeFilterToAlertType = (option: string): AlertType => {
  if (option === AlertTypeFilter.LOW_BATTERY) {
    return AlertType.LOW_BATTERY;
  }
  if (option === AlertTypeFilter.LOW_BRAKE_FLUID) {
    return AlertType.LOW_BRAKE_FLUID;
  }
  if (option === AlertTypeFilter.LOW_WASHER_FLUID) {
    return AlertType.LOW_WASHER_FLUID;
  }
  if (option === AlertTypeFilter.LOW_TIRE_PRESSURE) {
    return AlertType.LOW_TIRE_PRESSURE;
  }
  return AlertType.FLAT_TIRE;
};

const createContextQuery = (params: Partial<AlertParams>) => {
  return params.assetsId
    ? ({
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field: AlertQueryFieldType.SOURCE_ID,
        values: [params.assetsId],
      } as BDQuery)
    : params.fleetsId
      ? ({
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AlertQueryFieldType.FLEET_ID,
          values: [params.fleetsId],
        } as BDQuery)
      : params.hubsId
        ? ({
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AlertQueryFieldType.HUB_ID,
            values: [params.hubsId],
          } as BDQuery)
        : params.organizationsId
          ? ({
              qualifier: BDQueryCriteriaType.IS_EQUAL,
              field: AlertQueryFieldType.ORG_ID,
              values: [params.organizationsId],
            } as BDQuery)
          : undefined;
};
const createAssetTypeQuery = (selectedAssetTypes?: ApiAssetType[]) => {
  return selectedAssetTypes?.length
    ? {
        qualifier: BDQueryCriteriaType.IN,
        field: AlertQueryFieldType.SOURCE_TYPE,
        values: selectedAssetTypes,
      }
    : undefined;
};

const createAlertTypeQuery = (selectedAlertTypes?: AlertEvent[]) => {
  return selectedAlertTypes?.length
    ? {
        qualifier: BDQueryCriteriaType.IN,
        field: AlertQueryFieldType.ALERT_TYPE,
        values: selectedAlertTypes,
      }
    : undefined;
};

/**
 * Create query for selected receipt preferences
 * @param selectedReceiptPreferences array of selected receipt preferences
 * @returnsb query for selected receipt preferences
 */
const createAlertReceiptPreferenceQuery = (
  selectedReceiptPreferences?: ReceiptPreferenceType[]
) => {
  return selectedReceiptPreferences?.length
    ? {
        qualifier: BDQueryCriteriaType.ARRAY_CONTAINS,
        field: AlertQueryFieldType.RECEIPT_PREFERENCES,
        values: selectedReceiptPreferences,
      }
    : undefined;
};

export const createDateRangeQuery = (selectedDateRange?: AlertDateRange) => {
  if (!selectedDateRange) {
    return undefined;
  }
  const range = getDateRangeConfig(selectedDateRange);
  return createDateBetweenQuery(AlertQueryFieldType.TIMESTAMP, range);
};
const createFilterSessionQuery = (criteria?: AlertListFilterSession) => {
  return [
    createAssetTypeQuery(criteria?.selectedAssetTypes),
    createAlertTypeQuery(criteria?.selectedAlertTypes),
    createDateRangeQuery(criteria?.selectedDateRange),
    createAlertsSearchQuery(
      {
        idFieldName: AlertQueryFieldType.SOURCE_ID,
        nameFieldName: AlertQueryFieldType.SOURCE_NAME,
      },
      criteria?.searchCriteria
    ),
  ];
};

/**
 * alert query for getting alerts from backend
 * we always need to retrive only FEED alets
 * @param params alert params
 * @param criteria alert filter session
 * @returns alert BDQuery
 */
export const createAlertQuery = (
  params: Partial<AlertParams>,
  criteria?: AlertListFilterSession
): BDQuery | undefined => {
  const queries: BDQuery[] = [
    createContextQuery(params),
    ...createFilterSessionQuery(criteria),
    createAlertReceiptPreferenceQuery(['FEED']),
  ].filter((query) => !!query) as BDQuery[];
  if (!queries?.length) {
    return undefined;
  }
  return buildQueryTree(queries, 0, queries.length - 1, true);
};

const createEntitiesContextQuery = (params: Partial<AlertParams>) => {
  return params.assetsId
    ? ({
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field: AlertEntitiesQueryFieldType.SOURCE_ID,
        values: [params.assetsId],
      } as BDQuery)
    : params.fleetsId
      ? ({
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AlertEntitiesQueryFieldType.FLEET_ID,
          values: [params.fleetsId],
        } as BDQuery)
      : params.hubsId
        ? ({
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AlertEntitiesQueryFieldType.HUB_ID,
            values: [params.hubsId],
          } as BDQuery)
        : params.organizationsId
          ? ({
              qualifier: BDQueryCriteriaType.IS_EQUAL,
              field: AlertEntitiesQueryFieldType.ORG_ID,
              values: [params.organizationsId],
            } as BDQuery)
          : undefined;
};
export const createDateBetweenQuery = (
  field: string,
  range: {
    start: Date;
    end?: Date;
  }
): BDQuery => {
  return {
    qualifier: BDQueryCriteriaType.BETWEEN,
    field: field,
    values: [
      range.start.toISOString(),
      range.end
        ? range.end.toISOString()
        : DateTime.fromJSDate(range.start)
            .endOf('day')
            .toJSDate()
            .toISOString(),
    ],
  };
};
export const createEntitiesDateRangeQuery = (
  criteria?: AlertListFilterSession
) => {
  const range = getDateRangeConfig(
    criteria?.selectedDateRange || AlertDateRange.TODAY
  );

  if (criteria?.selectedAlertTypes?.length) {
    const queries = criteria.selectedAlertTypes.map((alertType) => {
      const field = `lastEventTimestampByEvent.${alertType}`;
      return createDateBetweenQuery(field, range);
    });
    return buildQueryTree(queries, 0, queries.length - 1, false);
  } else {
    return createDateBetweenQuery(AlertEntitiesQueryFieldType.TIMESTAMP, range);
  }
};

const createAlertsSearchQuery = (
  fieldNames: {
    idFieldName: string;
    nameFieldName: string;
  },
  searchCriteria?: SearchCriteria
) => {
  if (!searchCriteria?.input) {
    return undefined;
  }
  return createSearchQuery(
    fieldNames,
    searchCriteria.searchType,
    searchCriteria.input
  );
};

const createEntitiesFilterSessionQuery = (
  criteria?: AlertListFilterSession
) => {
  return [
    criteria?.selectedAssetTypes?.length
      ? {
          qualifier: BDQueryCriteriaType.IN,
          field: AlertEntitiesQueryFieldType.SOURCE_TYPE,
          values: criteria.selectedAssetTypes,
        }
      : undefined,
    createEntitiesDateRangeQuery(criteria),
    createAlertsSearchQuery(
      {
        idFieldName: AlertEntitiesQueryFieldType.SOURCE_ID,
        nameFieldName: AlertEntitiesQueryFieldType.SOURCE_NAME,
      },
      criteria?.searchCriteria
    ),
  ];
};

export const createAlertsEntitiesQuery = (
  params: Partial<AlertParams>,
  criteria?: AlertListFilterSession
): BDQuery | undefined => {
  const queries: BDQuery[] = [
    params ? createEntitiesContextQuery(params) : undefined,
    ...createEntitiesFilterSessionQuery(criteria),
  ].filter((query) => !!query) as BDQuery[];
  if (!queries?.length) {
    return undefined;
  }
  return buildQueryTree(queries, 0, queries.length - 1, true);
};

export const getTirePressureAlert = (
  exp: number,
  act: number
): AlertEvent.LOW_TIRE_PRESSURE | AlertEvent.FLAT_TIRE | undefined => {
  const { lowTire, flatTire, tirePressure } = {
    lowTire: exp * TIRES_THRESHOLD_LOW,
    flatTire: exp * TIRES_THRESHOLD_FLAT,
    tirePressure: act * 1,
  };
  if (isNaN(exp) || isNaN(act)) {
    return undefined;
  }
  return tirePressure > lowTire
    ? undefined
    : tirePressure > flatTire
      ? AlertEvent.LOW_TIRE_PRESSURE
      : AlertEvent.FLAT_TIRE;
};

export const isTirePressuresMatched = (
  displayedAlertContentVariableMap: AlertDetailsMap,
  alertContentVariableMap: AlertDetailsMap,
  tireAlertEvent: AlertEvent.FLAT_TIRE | AlertEvent.LOW_TIRE_PRESSURE
): boolean => {
  const displayedAlertTires = {
    frontLeft:
      getTirePressureAlert(
        Number(displayedAlertContentVariableMap.tires_front_exp_kpa),
        Number(displayedAlertContentVariableMap.tire_driver_front_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
    frontRight:
      getTirePressureAlert(
        Number(displayedAlertContentVariableMap.tires_front_exp_kpa),
        Number(displayedAlertContentVariableMap.tire_pass_front_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
    rearLeft:
      getTirePressureAlert(
        Number(displayedAlertContentVariableMap.tires_rear_exp_kpa),
        Number(displayedAlertContentVariableMap.tire_driver_rear_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
    rearRight:
      getTirePressureAlert(
        Number(displayedAlertContentVariableMap.tires_rear_exp_kpa),
        Number(displayedAlertContentVariableMap.tire_pass_rear_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
  };

  const alertTires = {
    frontLeft:
      getTirePressureAlert(
        Number(alertContentVariableMap.tires_front_exp_kpa),
        Number(alertContentVariableMap.tire_driver_front_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
    frontRight:
      getTirePressureAlert(
        Number(alertContentVariableMap.tires_front_exp_kpa),
        Number(alertContentVariableMap.tire_pass_front_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
    rearLeft:
      getTirePressureAlert(
        Number(alertContentVariableMap.tires_rear_exp_kpa),
        Number(alertContentVariableMap.tire_driver_rear_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
    rearRight:
      getTirePressureAlert(
        Number(alertContentVariableMap.tires_rear_exp_kpa),
        Number(alertContentVariableMap.tire_pass_rear_act_kpa)
      ) === tireAlertEvent
        ? tireAlertEvent
        : undefined,
  };

  return JSON.stringify(displayedAlertTires) === JSON.stringify(alertTires);
};

export const mapEventToMessage = (event: any, translations: any) => {
  if (!event || !event.eventType) {
    return (
      <Trans
        i18nKey="settings:notifications.messages.unknownEventType"
        values={{ eventType: 'Unknown' }}
      />
    );
  }

  const searchKey = event.eventType ? event.eventType : event?.event?.event;

  // Ensure contentVariableMap is always defined and safely destructured
  const contentVars = event?.contentVariableMap || {};

  const createTransMessage = (
    i18nKey: string,
    linkHref: string,
    values: object
  ) => {
    return (
      <Trans
        i18nKey={i18nKey}
        components={{
          alink: <Link href={linkHref} rel="noopener noreferrer" />,
        }}
        values={values}
      />
    );
  };
  switch (searchKey) {
    case AlertEvent.ACCOUNT_UPDATE:
      return translations['settings:notifications.messages.profileUpdated'];

    case AlertEvent.BRAKE_PADS:
      return createTransMessage(
        'settings:notifications.messages.brake-pads',
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_objectid: contentVars.alert_objectid || 'N/A',
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
          alert_object_fault_code:
            contentVars.alert_object_fault_code || 'Unknown',
        }
      );

    case AlertEvent.ONBOARDING_VEHICLE:
      return createTransMessage(
        'settings:notifications.messages.onboardVehicle',
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_vehicle_description: contentVars.alert_objectid || 'N/A',
          alert_object_event_type:
            contentVars.alert_object_event_type || 'Unknown',
          alert_objectid: contentVars.alert_objectid || 'N/A',
        }
      );

    case AlertEvent.OIL_LIFE:
      return createTransMessage(
        'settings:notifications.messages.oilLife',
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
          alert_object_alias_oil_life:
            contentVars.alert_object_alias_oil_life || 'Unknown',
        }
      );

    case AlertEvent.FLAT_TIRE:
    case AlertEvent.LOW_TIRE_PRESSURE:
    case AlertEvent.LOW_BATTERY:
    case AlertEvent.LOW_WASHER_FLUID:
    case AlertEvent.ENGINE_AIR_FILTER:
      return createTransMessage(
        `settings:notifications.messages.${searchKey.toLowerCase()}`,
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
        }
      );

    case AlertEvent.ANTILOCK_BRAKING_SYSTEM:
    case AlertEvent.AIRBAG_SYSTEM:
    case AlertEvent.EMISSION_SYSTEM:
    case AlertEvent.ENGINE_AND_TRANSMISSION:
    case AlertEvent.LITHIUM_ION_BATTERY:
    case AlertEvent.LOW_BRAKE_FLUID:
    case AlertEvent.STABILITY_CONTROL_SYSTEM:
    case AlertEvent.ONSTAR_SYSTEM:
    case AlertEvent.ELECTRIC_DRIVE_SYSTEM: {
      const criticalKeySuffix =
        contentVars.alert_object_dtc_criticality === 'A critical issue'
          ? 'Critical'
          : 'NonCritical';

      const i18nKey = `settings:notifications.messages.${searchKey.toLowerCase()}${criticalKeySuffix}`;
      return createTransMessage(
        i18nKey,
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
          alert_object_fault_code:
            contentVars.alert_object_fault_code || 'Unknown',
        }
      );
    }

    case AlertEvent.ADD_USER:
    case AlertEvent.REMOVE_USER:
      return (
        <Trans
          i18nKey={`settings:notifications.messages.${searchKey.toLowerCase()}`}
          values={{
            alert_object_user_first_name:
              contentVars.alert_object_user_first_name || 'N/A',
            alert_object_user_last_name:
              contentVars.alert_object_user_last_name || 'N/A',
          }}
        />
      );

    default:
      return (
        <Trans
          i18nKey="settings:notifications.messages.unknownEventType"
          values={{ eventType: searchKey || 'Unknown' }}
        />
      );
  }
};

export const eventToMessage = (
  event: AlertDto,
  translations: typeof NOTIFICATION_SETTINGS_MESSAGES
) => {
  if (!event?.event?.event) {
    return (
      <Trans
        i18nKey="settings:notifications.messages.unknownEventType"
        values={{ eventType: undefined }}
      />
    );
  }

  const searchKey = event.event.event;

  // Ensure contentVariableMap is always defined and safely destructured
  const contentVars = (event.event?.contentVariableMap ||
    {}) as unknown as Record<string, string>;

  const createTransMessage = (
    i18nKey: string,
    linkHref: string,
    values: object
  ) => {
    return (
      <Trans
        i18nKey={i18nKey}
        components={{
          alink: <Link href={linkHref} rel="noopener noreferrer" />,
        }}
        values={values}
        data-heap-redact-text
      />
    );
  };

  switch (searchKey) {
    case AlertEvent.ACCOUNT_UPDATE:
      return translations['settings:notifications.messages.profileUpdated'];

    case AlertEvent.BRAKE_PADS:
      return createTransMessage(
        'settings:notifications.messages.brake-pads',
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_objectid: contentVars.alert_objectid || 'N/A',
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
          alert_object_fault_code:
            contentVars.alert_object_fault_code || 'Unknown',
        }
      );

    case AlertEvent.ONBOARDING_VEHICLE:
      return createTransMessage(
        'settings:notifications.messages.onboardVehicle',
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_vehicle_description: contentVars.alert_objectid || 'N/A',
          alert_object_event_type:
            contentVars.alert_object_event_type || 'Unknown',
          alert_objectid: contentVars.alert_objectid || 'N/A',
        }
      );

    case AlertEvent.OIL_LIFE:
      return createTransMessage(
        'settings:notifications.messages.oilLife',
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
          alert_object_alias_oil_life:
            contentVars.alert_object_alias_oil_life || 'Unknown',
        }
      );

    case AlertEvent.FLAT_TIRE:
    case AlertEvent.LOW_TIRE_PRESSURE:
    case AlertEvent.LOW_BATTERY:
    case AlertEvent.LOW_WASHER_FLUID:
    case AlertEvent.ENGINE_AIR_FILTER:
      return createTransMessage(
        `settings:notifications.messages.${searchKey.toLowerCase()}`,
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
        }
      );

    case AlertEvent.ANTILOCK_BRAKING_SYSTEM:
    case AlertEvent.AIRBAG_SYSTEM:
    case AlertEvent.EMISSION_SYSTEM:
    case AlertEvent.ENGINE_AND_TRANSMISSION:
    case AlertEvent.LITHIUM_ION_BATTERY:
    case AlertEvent.LOW_BRAKE_FLUID:
    case AlertEvent.STABILITY_CONTROL_SYSTEM:
    case AlertEvent.ONSTAR_SYSTEM:
    case AlertEvent.ELECTRIC_DRIVE_SYSTEM: {
      const criticalKeySuffix =
        contentVars.alert_object_dtc_criticality === 'A critical issue'
          ? 'Critical'
          : 'NonCritical';

      const i18nKey = `settings:notifications.messages.${searchKey.toLowerCase()}${criticalKeySuffix}`;
      return createTransMessage(
        i18nKey,
        buildAssetsUrl(contentVars.alert_objectid || '#'),
        {
          alert_object_alias: contentVars.alert_object_alias || 'Unknown',
          alert_object_fault_code:
            contentVars.alert_object_fault_code || 'Unknown',
        }
      );
    }

    case AlertEvent.ADD_USER:
    case AlertEvent.REMOVE_USER:
      return (
        <Trans
          i18nKey={`settings:notifications.messages.${searchKey.toLowerCase()}`}
          values={{
            alert_object_user_first_name:
              contentVars.alert_object_user_first_name || 'N/A',
            alert_object_user_last_name:
              contentVars.alert_object_user_last_name || 'N/A',
          }}
          data-heap-redact-text
        />
      );

    default:
      return (
        <Trans
          i18nKey="settings:notifications.messages.unknownEventType"
          values={{ eventType: undefined }}
        />
      );
  }
};
