import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  'common--cmp--box-spinner': {
    display: 'grid',
    minHeight: '75px',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      animation: '$spin 1s infinite ease-in-out',
    },
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
