import { useTranslations } from '@brightdrop/localization-client';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useEffect } from 'react';

import type { AlertDto } from '~/api/alerts/generated-api';
import { useAppDispatch, useAppSelect } from '~/app/store';
import GreenCircledCheckMark from '~/assets/icons/new/greenCircledCheckMark.svg?react';
import Spinner from '~/common/components/Spinner/Spinner';
import {
  updateAlert,
  useUpdateAlertToReadMutation,
  useUpdateAlertToUnReadMutation,
} from '~/features/alerts/alertsSlice';
import { selectAlerts } from '~/features/alerts/alertsSlice.selectors';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';
import { TabType } from '~/routes/notifications/Notifications';

import NotificationItem from './ListItem/NotificationItem';
import { useStyles } from './NotificationList.styles';

type NotificationListProps = {
  tabValue: string;
  ulRef?: React.MutableRefObject<HTMLUListElement | null>;
  loading?: boolean;
};

function NotificationList({ tabValue, ulRef, loading }: NotificationListProps) {
  const { classes } = useStyles();
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);

  const dispatch = useAppDispatch();
  const [alerts, unreadAlerts] = useAppSelect(selectAlerts);
  const [handleRead, readResults] = useUpdateAlertToReadMutation();
  const [handleUnread, unReadResults] = useUpdateAlertToUnReadMutation();

  useEffect(() => {
    if (unReadResults.isLoading || readResults.isLoading) {
      return;
    }

    const data = unReadResults.data?.result || readResults.data?.result;
    if (data) {
      dispatch(updateAlert(data));
    }
  }, [
    unReadResults.isLoading,
    readResults.isLoading,
    unReadResults.data,
    readResults.data,
    dispatch,
  ]);

  let list = alerts;
  if (tabValue === TabType.unread) {
    list = unreadAlerts;
  }

  const handleClick = (item: AlertDto) => {
    if (!item.id) {
      return;
    }
    readResults.reset();
    unReadResults.reset();

    if (item.read) {
      handleUnread({ id: item.id });
    } else {
      handleRead({ id: item.id });
    }
  };

  let Comp = (
    <ListItem
      className={
        classes[
          'rt--notifications--cmp--list--notification-list--box-container--ul--li-done'
        ]
      }
    >
      <Box>
        <GreenCircledCheckMark />
      </Box>
      <Typography variant={'body_medium'}>
        {tabValue === TabType.all
          ? translations['settings:notifications.messages.noneAtAll']
          : translations['settings:notifications.messages.noUnread']}
      </Typography>
    </ListItem>
  );

  if (list.length) {
    Comp = (
      <>
        {list.map((item) => (
          <NotificationItem
            item={item}
            key={`${item.id}_${item.read}`}
            handleClick={handleClick}
          />
        ))}
      </>
    );
  }

  return (
    <Box
      className={
        classes[
          'rt--notifications--cmp--list--notification-list--box-container'
        ]
      }
    >
      <List data-testid="CSPlatformList" ref={ulRef}>
        {Comp}
        <Spinner show={loading} />
      </List>
    </Box>
  );
}

export default NotificationList;
