import type { OnServerDataMessageArgs } from '@azure/web-pubsub-client';
import { IconButton } from '@brightdrop/bd-ui';
import { Popover } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';

import { AlertDto } from '~/api/alerts/generated-api';
import { useAppDispatch, useAppSelect } from '~/app/store';
import AlertRedCircleIcon from '~/assets/icons/new/alertRedCircleIcon.svg?react';
import NotificationsIcon from '~/assets/icons/notificationsIcon.svg?react';
import usePubSubSocket from '~/common/hooks/PubSub/usePubSubSocket';
import {
  addAlert,
  setAlerts,
  setPopUp,
  useGetAlertsForCurrentUserQuery,
} from '~/features/alerts/alertsSlice';
import {
  selectAlerts,
  selectPopUp,
} from '~/features/alerts/alertsSlice.selectors';
import Notifications from '~/routes/notifications/Notifications';

import useStyles from './NotificationBadge.styles';

const NotificationBadge = () => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const isPopoverOpen = useAppSelect(selectPopUp);
  const [, unreadAlerts] = useAppSelect(selectAlerts);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { data, isLoading } = useGetAlertsForCurrentUserQuery({ size: 20 });
  const showUnread = Boolean(unreadAlerts?.length);

  const handleAddAlert = useCallback(
    (event: OnServerDataMessageArgs) => {
      const { message } = event;
      if (typeof message.data === 'object' && 'data' in message.data) {
        dispatch(addAlert(message.data.data as AlertDto));
      }
    },
    [dispatch]
  );

  usePubSubSocket({ cb: handleAddAlert });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    dispatch(
      setAlerts({
        alerts: data?.result?.items ?? [],
        totalAlerts: data?.result?.total_items ?? 0,
      })
    );
  }, [isLoading, dispatch, data?.result?.items, data?.result?.total_items]);

  const handleClickNotification = () => {
    dispatch(setPopUp(true));
  };

  const handleCloseNotification = () => {
    dispatch(setPopUp(false));
  };

  const generateNotificationIconClassname = () => {
    const selectedClass = isPopoverOpen ? 'selected' : '';
    return `${classes.notificationIconButton} ${selectedClass}`;
  };

  const NotificationIcon = () => (
    <IconButton
      size="medium"
      data-testid="notifications-icon"
      aria-label="Notifications"
      aria-haspopup="true"
      onClick={handleClickNotification}
      className={generateNotificationIconClassname()}
      icon={<NotificationsIcon />}
    />
  );

  const UnreadNotificationIcon = () => (
    <IconButton
      size="medium"
      data-testid="notifications-icon-unread"
      aria-haspopup="true"
      aria-label="Unread Notifications"
      onClick={handleClickNotification}
      className={generateNotificationIconClassname()}
      icon={
        <>
          <NotificationsIcon />
          <AlertRedCircleIcon className={classes.alertRedCircleIcon} />
        </>
      }
    />
  );

  let Icon = <NotificationIcon />;
  if (showUnread) {
    Icon = <UnreadNotificationIcon />;
  }

  return (
    <div ref={divRef}>
      {Icon}
      <Popover
        open={isPopoverOpen}
        anchorEl={divRef.current}
        classes={{ paper: classes.popoverContent }}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Notifications />
      </Popover>
    </div>
  );
};

export default NotificationBadge;
