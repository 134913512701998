import { FormState } from '@brightdrop/bd-form';
import { Role } from '@gm-commercial/profile-model';

import { GmResponseCommonSubscriptionDto } from '~/api/alerts/generated-api';

import { SubscriptionEvent } from './alert.model';
import { Entity, NonNullablePick } from './common.model';

// WIP Model
export enum NotificationType {
  VEHICLE = 'vehicle',
  DIAGNOSTICS = 'diagnostics',
  USER_ACTIVITY = 'user',
  ACCOUNT_ACTIVITY = 'account',
}

export enum VehicleSubscriberType {
  ALL_VEHICLES = 'all_vehicles',
  SELECTED_VEHICLES = 'selected_vehicles',
}

export interface Vehicle {
  id: string;
  name: string;
  organization: {
    id: string;
    name: string;
  };
  fleet: {
    id: string;
    name: string;
  };
  vehicleType: {
    make: string | null;
    year: string | null;
    model: string | null;
    propulsion: string | null;
  };
  image: string | undefined;
  licensePlate: string | null;
  vin: string | null;
}

export enum NotificationDiagnosticsType {
  AIRBAG_SYSTEM = 'airbagSystem',
  EMISSIONS_SYSTEM = 'emissionsSystem',
  OIL_LIFE = 'oilLife',
  ENGINE_TRANSMISSIONS = 'engineAndTransmission',
  ONSTAR_SYTEM = 'onstarSystem',
  LITHIUM_ION_BATTERY = 'lithiumIonBattery',
  REGENERATIVE_BRAKE_SYSTEMS = 'regenerativeBrakeSystems',
  BRAKE_PAD = 'brakePad',
  WASHER_FLUID = 'washerFluid',
  STABILITY_CONTROL_SYSTEM = 'stabilityControlSystem',
  LOW_BATTERY = 'lowBattery',
  LOW_TIRE_PRESURE = 'lowTirePressure',
  ANTI_LOCK_BRAKING_SYSTEM = 'antilockBrakingSystem',
  ELECTRIC_DRIVE_UNIT = 'electricDriveUnit',
  LOW_DIESEL_EXHAUST_FLUID = 'lowDieselExhaustFluid',
  ENGINE_AIR_FILTER = 'engineAirFilter',
  BRAKE_FLUID = 'brakeFluid',
  FLAT_TIRE = 'flatTire',
  LOW_STATE_OF_CHARGE = 'lowStateOfCharge',
}

export enum NotificationTab {
  VEHICLE_ACTIVITY = 'activity',
  DIAGNOSTICS = 'diagnostics',
}

export type NotificationReceiptPreferences = 'EMAIL' | 'FEED';

export interface Notification extends Entity {
  type: NotificationType;
  receiptPreferences?: Array<NotificationReceiptPreferences>;
  isActivated?: boolean;
}

export type NotificationCardFormFields = {
  receiptPreferences: Array<NotificationReceiptPreferences> | null;
  name: string | null;
  selectedEvents: Array<SubscriptionEvent> | null;
  selectedAssets?: Array<string> | null;
  selectedVehicleSubscriberType?: VehicleSubscriberType | null;
};

export type NotificationCardFormErrors = {
  [k in keyof NotificationCardFormFields]: string;
};

export type NotificationFormState = FormState<NotificationCardFormFields>;

export type NotificationCardFormSubmitResponse =
  Promise<GmResponseCommonSubscriptionDto>;

export type ValidNotificationForm = NonNullablePick<
  NotificationCardFormFields,
  'receiptPreferences'
> &
  Pick<
    NotificationCardFormFields,
    | 'name'
    | 'selectedEvents'
    | 'selectedAssets'
    | 'selectedVehicleSubscriberType'
  >;

export enum NotificationFormFieldNames {
  NAME = 'name',
  RECEIPT_PREFERENCES = 'receiptPreferences',
  SELECTED_ASSETS = 'selectedAssets',
  SELECTED_EVENTS = 'selectedEvents',
  SELECTED_VEHICLE_SUBSCRIBER_TYPE = 'selectedVehicleSubscriberType',
}

export enum NotificationCategory {
  PROFILE = 'profile',
  MAINTENANCE = 'maintenance',
}

export const NOTIFICATIONS_REQUIRED_ROLES = [
  Role.ORG_MANAGER,
  Role.FLEET_MANAGER,
];
