import { useMemo } from 'react';
import { useParams } from 'react-router';

import { RouteParams } from '../configs/route.config';
import { DEFAULT_SESSION_CONFIG_KEY } from '../constants/common.constant';
import useCurrentContext from './useCurrentContext';
import useSearchParams from './useSearchParams';

const useSessionId = (
  withCurrentContext?: boolean,
  withSearchParams?: boolean,
  isFleetSwitcherEnabled?: boolean
): string => {
  const params = useParams<RouteParams>();
  const currentContext = useCurrentContext(isFleetSwitcherEnabled);
  const searchParams = useSearchParams();

  const contextSessionId = useMemo(
    () =>
      withCurrentContext
        ? currentContext?.fleetsId ||
          currentContext?.hubsId ||
          currentContext?.organizationsId
        : undefined,
    [
      currentContext?.fleetsId,
      currentContext?.hubsId,
      currentContext?.organizationsId,
      withCurrentContext,
    ]
  );

  const searchParamsSessionId = useMemo(
    () =>
      withSearchParams
        ? searchParams?.assetsId ||
          searchParams?.fleetsId ||
          searchParams?.hubsId ||
          searchParams?.organizationsId
        : undefined,
    [
      searchParams?.assetsId,
      searchParams?.fleetsId,
      searchParams?.hubsId,
      searchParams?.organizationsId,
      withSearchParams,
    ]
  );

  const sessionId = useMemo(() => {
    const id =
      contextSessionId ||
      searchParamsSessionId ||
      params?.assetsId ||
      params?.fleetsId ||
      params?.hubsId ||
      params?.organizationsId ||
      DEFAULT_SESSION_CONFIG_KEY;
    return id;
  }, [
    contextSessionId,
    params?.assetsId,
    params?.fleetsId,
    params?.hubsId,
    params?.organizationsId,
    searchParamsSessionId,
  ]);

  return sessionId;
};

export default useSessionId;
