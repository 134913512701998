import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '~/app/rootReducer';
import { DEFAULT_PAGINATION_PAGE } from '~/common/constants/common.constant';

import {
  alertsAdapter,
  AlertsEntitiesState,
  AlertSessionViewType,
} from './alertsSlice';

const selectAlertsState = (state: RootState): RootState['alerts'] =>
  state.alerts;

const notificationsSelector = alertsAdapter.getSelectors<RootState['alerts']>(
  (state) => {
    return state.alerts;
  }
);

export const selectAlerts = createSelector(selectAlertsState, (state) => {
  const alerts = notificationsSelector.selectAll(state);
  const unreadAlerts = alerts.filter((item) => !item.read);
  return [alerts, unreadAlerts];
});

export const selectPopUp = createSelector(
  selectAlertsState,
  (state) => state.popUpIsOpen
);

export const selectHasMoreAlerts = createSelector(
  selectAlertsState,
  (state) => {
    const { totalAlerts } = state;
    const alerts = notificationsSelector.selectAll(state);
    return alerts.length < totalAlerts;
  }
);

export const selectEditableSubscriptionId = createSelector(
  selectAlertsState,
  (state) => state.editableSubscriptionId
);

export const selectHighlightedSubscriptionId = createSelector(
  selectAlertsState,
  (state) => state.highlightedSubscriptionId
);

const selectAlertEntitiesSessions = createSelector(
  selectAlertsState,
  (alertsState) => alertsState.entitiesSessions
);

const makeAlertEntitiesStateBySessionIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => AlertsEntitiesState) =>
  createSelector(
    selectAlertEntitiesSessions,
    (items) => items?.[viewType]?.[sessionId] || {}
  );

export const makeAlertsEntitiesPageNumberSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => number) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.page || DEFAULT_PAGINATION_PAGE
  );

export const makeAlertsEntitiesTotalCountSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => number | undefined) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.count
  );
