import { useEffect, useRef } from 'react';

type Props = {
  callbackFn: () => void;
  intersectProps?: IntersectionObserverInit | null;
};

const DEFAULT_OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
};

const useInfiniteScroll = <T extends HTMLElement>({
  callbackFn,
  intersectProps = null,
}: Props) => {
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    let timeoutID: ReturnType<typeof setTimeout> | undefined;

    const callback: IntersectionObserverCallback = (entries, observer) => {
      const lastElement = entries.at(-1);
      if (timeoutID) {
        clearTimeout(timeoutID);
      }

      if (lastElement?.isIntersecting) {
        timeoutID = setTimeout(() => {
          callbackFn();
          observer.unobserve(lastElement.target);
          timeoutID = undefined;
        }, 500);
      }
    };

    const options = { ...DEFAULT_OPTIONS, ...intersectProps };
    const observer = new IntersectionObserver(callback, options);

    const children = Array.from(elementRef.current.children);
    const lastElement = children.at(-1);
    if (lastElement) {
      observer.observe(lastElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [callbackFn, intersectProps]);

  return { elementRef };
};

export default useInfiniteScroll;
