import { useTranslations } from '@brightdrop/localization-client';
import {
  Avatar,
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import { AlertDto } from '~/api/alerts/generated-api';
import { useAppDispatch } from '~/app/store';
import CheckSquareIcon from '~/assets/icons/new/checkSquareIcon.svg?react';
import NotificationIcon from '~/assets/icons/new/notification.svg?react';
import SettingsIcon from '~/assets/icons/new/settings.svg?react';
import CSPlatformDateTime from '~/common/components/date/CSPlatformDateTime';
import CSPlatformSettingsMenu from '~/common/components/SettingsMenu/CSPlatformSettingsMenu';
import { AlertEvent } from '~/common/models/alert.model';
import { NotificationType } from '~/common/models/notification.model';
import {
  SETTINGS_NOTIFICATIONS_ACCOUNT,
  SETTINGS_NOTIFICATIONS_USERS,
  SETTINGS_NOTIFICATIONS_VEHICLES_ACTIVITY,
  SETTINGS_NOTIFICATIONS_VEHICLES_DIAGNOSTICS,
} from '~/common/models/pages/settingsPages.model';
import { toDateStringWithTimezone } from '~/common/utils/date-time.utils';
import {
  setEditableSubscriptionId,
  setHighlightedSubscriptionId,
} from '~/features/alerts/alertsSlice';
import { eventToMessage } from '~/features/alerts/utils/alerts.utils';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

import { EVENT_NOTIFICATION_MAPPING, iconMap } from './constants';
import { useStyles } from './NotificationItem.styles';

type NotificationItemProps = {
  item: AlertDto;
  handleClick: (item: AlertDto) => void;
};

function NotificationItem({ item, handleClick }: NotificationItemProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { classes } = useStyles();
  const theme = useTheme();
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);

  const handleOnClick = () => {
    handleClick(item);
  };

  const getSettingsMenuItems = () => [
    {
      text: item.read
        ? translations['settings:notifications.markAsUnRead']
        : translations['settings:notifications.markAsRead'],
      icon: item.read ? (
        <NotificationIcon data-testid="notifications-row-options-mark-as-unread" />
      ) : (
        <CheckSquareIcon data-testid="notifications-row-options-mark-as-read" />
      ),
      onClick: () => {
        handleClick(item);
      },
    },
    {
      text: translations['settings:notifications.editSettings'],
      icon: <SettingsIcon data-testid="notifications-options-edit-settings" />,
      onClick: () => {
        const notificationType =
          EVENT_NOTIFICATION_MAPPING[item?.event?.event as AlertEvent];
        dispatch(setEditableSubscriptionId(item?.subscriptionId));
        dispatch(setHighlightedSubscriptionId(item?.subscriptionId));
        switch (notificationType) {
          case NotificationType.ACCOUNT_ACTIVITY:
            history.push(SETTINGS_NOTIFICATIONS_ACCOUNT);
            break;
          case NotificationType.USER_ACTIVITY:
            history.push(SETTINGS_NOTIFICATIONS_USERS);
            break;
          case NotificationType.VEHICLE:
            history.push(SETTINGS_NOTIFICATIONS_VEHICLES_ACTIVITY);
            break;
          default:
            history.push(SETTINGS_NOTIFICATIONS_VEHICLES_DIAGNOSTICS);
        }
      },
    },
  ];

  return (
    <ListItem
      className={
        classes[
          'rt--notifications--cmp--list--list-item--notification-item--list-item'
        ]
      }
      divider={true}
      onClick={handleOnClick}
      secondaryAction={
        <CSPlatformSettingsMenu options={getSettingsMenuItems()} />
      }
    >
      <ListItemAvatar>
        <Avatar
          className={`${classes.icon} ${item.read ? '' : classes.unRead}`}
        >
          <img
            src={iconMap[item.event.event]}
            alt={item.event.event}
            width="24"
            height="24"
          />
        </Avatar>
        {!item.read && (
          <Badge
            data-testid="unread-notification-icon"
            aria-label="Unread Notification"
            overlap="circular"
            badgeContent=" "
            variant="dot"
            className={classes.badge}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        data-testid="CSPlatformListItemText"
        primary={
          <Typography variant="body_regular">
            {eventToMessage(item, translations)}
          </Typography>
        }
        secondary={
          <Typography
            variant="label_regular"
            color={theme.new.color.textSecondary}
          >
            <CSPlatformDateTime
              date={toDateStringWithTimezone(item.event.eventTimestamp)}
            />
          </Typography>
        }
      />
    </ListItem>
  );
}

export default NotificationItem;
