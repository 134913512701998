import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';

import {
  FeatureFlag,
  FeatureFlagValueType,
} from '../models/featureFlags.model';

const useFeatureFlags = (): {
  getFlag: (name: string) => FeatureFlagValueType | undefined;
  featureFlags?: FeatureFlag[];
} => {
  const flags = useFlags();

  const featureFlags: Array<FeatureFlag> = useMemo(
    () =>
      Object.entries(flags).map(([name, value]) => ({
        name,
        value,
      })),
    [flags]
  );

  const getFlag = useCallback(
    (name: string) => flags[name] ?? undefined,
    [flags]
  );

  return {
    featureFlags,
    getFlag,
  };
};

export default useFeatureFlags;
