import { VehicleDetailsType } from './asset-report.model';
import { Entity, NonNullablePick } from './common.model';

export type AddVehicleItem = {
  // This will likely grow so I am creating this now even though it only has one field
  vin: string;
};

export type AddVehiclesFormFields = {
  vinData: File | AddVehicleItem[];
  fleetId: string;
  legalTermsAgreement?: boolean;
};

export type ValidAddVehiclesFormFields = NonNullablePick<
  AddVehiclesFormFields,
  'fleetId'
> &
  Pick<AddVehiclesFormFields, 'vinData'>;

export type AddVehiclesApiRequest = {
  fleetId: string;
  vinData: File;
};

export type AddVehiclesApiResponse = {
  batchId: string;
};

export enum AddVehiclesFieldNames {
  VIN_DATA = 'vinData',
  FLEET_ID = 'fleetId',
  LEGAL_TERMS_AGREEMENT = 'legalTermsAgreement',
}

export enum VehicleRequestType {
  VEHICLE_ADD = 'Vehicle Add',
}

export interface AddVehicleReportItem extends Entity {
  action?: string;
  added?: string;
  vehicleType?: VehicleDetailsType;
}
