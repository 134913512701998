import { Profile } from '@gm-commercial/profile-model';
import { createSelector } from 'reselect';

import { DEFAULT_PAGINATION_ROW_PER_PAGE } from '~/common/constants/common.constant';
import {
  BDRequestStatus,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  MAP_APP_LOCALE_TO_USER_LOCALE,
  UserSupportedLocale,
} from '~/common/models/common.model';
import { BDError } from '~/common/models/error.model';
import { IDLE_OPERATION_STATUS } from '~/common/utils/store/selector.helper';

import { RootState } from '../../app/rootReducer';
import { selectUserById } from '../users/usersSlice';
import { ProfileRequestType, selectProfileState } from './profileSlice';

export const selectProfile = createSelector(
  selectProfileState,
  (profileState) => profileState.profile
);

export const selectActingProfile = createSelector(
  selectProfileState,
  (profileState) => profileState.actingProfile
);

/**
 * Utility function to create separate memoized profile prop selectors at runtime
 */
export const makeSelectProfileProp = <K extends keyof Profile>(
  prop: K
): ((state: RootState) => Profile[K] | undefined) =>
  createSelector(selectProfile, (profile) => profile?.[prop]);

export const makeSelectActingProfileProp = <K extends keyof Profile>(
  prop: K
): ((state: RootState) => Profile[K] | undefined) =>
  createSelector(selectActingProfile, (profile) => profile?.[prop]);

export const selectProfileId = makeSelectProfileProp('id');
export const selectProfileEmail = makeSelectProfileProp('email');
export const selectProfileName = makeSelectProfileProp('name');
export const selectProfileAddress = makeSelectProfileProp('address');
export const selectPermissions = makeSelectProfileProp('permissions');
export const selectPermissionsContextIds = makeSelectProfileProp(
  'permissionsContextIds'
);
export const selectActingPermissionsContextIds = makeSelectActingProfileProp(
  'permissionsContextIds'
);
export const selectRole = makeSelectProfileProp('role');
export const selectProfileStatus = makeSelectProfileProp('status');
export const selectProfileOrganization = makeSelectProfileProp('organization');
export const selectProfileHub = makeSelectProfileProp('hub');
export const selectProfileFleet = makeSelectProfileProp('fleet');
export const selectProfileInvite = makeSelectProfileProp('invite');

export const selectActingProfileId = makeSelectActingProfileProp('id');
export const selectActingProfileEmail = makeSelectActingProfileProp('email');
export const selectActingProfileName = makeSelectActingProfileProp('name');
export const selectActingProfileFirstName =
  makeSelectActingProfileProp('firstName');
export const selectActingProfileLastName =
  makeSelectActingProfileProp('lastName');
export const selectActingProfileAddress =
  makeSelectActingProfileProp('address');
export const selectActingPermissions =
  makeSelectActingProfileProp('permissions');
export const selectActingRole = makeSelectActingProfileProp('role');
export const selectActingProfileStatus = makeSelectActingProfileProp('status');
export const selectActingProfileOrganization =
  makeSelectActingProfileProp('organization');
export const selectActingProfileHub = makeSelectActingProfileProp('hub');
export const selectActingProfileFleet = makeSelectActingProfileProp('fleet');

export const selectProfileOperation = (
  requestType: ProfileRequestType
): ((state: RootState) => OperationStatus) =>
  createSelector(
    (state: RootState) => selectProfileState(state).operations,
    (operations) => operations[requestType] || IDLE_OPERATION_STATUS
  );

export const selectProfileOperationStatus = (
  requestType: ProfileRequestType
): ((state: RootState) => BDRequestStatus) =>
  createSelector(
    (state: RootState) =>
      selectProfileState(state).operations[requestType] ||
      IDLE_OPERATION_STATUS,
    (operationStatus) => operationStatus?.status
  );

export const selectProfileOperationErrors = (
  requestType: ProfileRequestType
): ((state: RootState) => BDError<unknown>[] | undefined) =>
  createSelector(
    (state: RootState) =>
      selectProfileState(state).operations[requestType] ||
      IDLE_OPERATION_STATUS,
    (operationStatus) => operationStatus?.errors
  );

export const selectProfileContextIds = createSelector(
  selectProfileOrganization,
  selectProfileHub,
  selectProfileFleet,
  (org, hub, fleet) => ({
    organizationsId: org?.id,
    hubsId: hub?.id,
    fleetsId: fleet?.id,
  })
);

export const selectActingProfileContextIds = createSelector(
  selectActingProfileOrganization,
  selectActingProfileHub,
  selectActingProfileFleet,
  (org, hub, fleet) => ({
    organizationsId: org?.id,
    hubsId: hub?.id,
    fleetsId: fleet?.id,
  })
);

export const selectAllowedContexts = createSelector(
  selectProfileState,
  (state) => state.allowedContexts
);

export const selectEffectiveContext = createSelector(
  selectProfileState,
  (state) => state.effectiveContext
);

export const selectSelectedContext = createSelector(
  selectProfileState,
  (state) => state.selectedContext
);

export const makeProfileOperationSelector = (requestType: ProfileRequestType) =>
  createSelector(
    selectProfileOperationStatus(requestType),
    selectProfileOperationErrors(requestType),
    (status, errors) => ({
      status,
      errors,
    })
  );

export const profileOperationSelector = makeProfileOperationSelector(
  ProfileRequestType.GET_PROFILE
);

export const profileRequestStatusSelector = createSelector(
  profileOperationSelector,
  (getProfileRequest) => getProfileRequest?.status
);

export const permissionsOperationSelector = makeProfileOperationSelector(
  ProfileRequestType.GET_PERMISSIONS
);

export const permissionsRequestStatusSelector = createSelector(
  permissionsOperationSelector,
  (getPermissionRequest) => getPermissionRequest?.status
);

export const onboardingStatusOperationSelector = makeProfileOperationSelector(
  ProfileRequestType.GET_ONBOARDING_STATE
);

export const allowedContextOperationSelector = makeProfileOperationSelector(
  ProfileRequestType.GET_ALLOWED_CONTEXTS
);

export const selectGetProfileOperation = makeProfileOperationSelector(
  ProfileRequestType.GET_PROFILE
);

export const selectOnboardingStatus = createSelector(
  selectProfileState,
  (state) => state.onboardingState
);

export const selectProfileUserPreferences = createSelector(
  selectProfile,
  (profile) => profile?.userPreferences
);

export const selectCurrentLocale = createSelector(
  selectProfileState,
  (state) => state.currentLocale
);

export const selectCurrentLanguage = createSelector(
  selectCurrentLocale,
  (locale) => locale?.split('-')[0]
);

export const selectRowsPerPage = createSelector(
  selectProfileState,
  (state) => state.rowsPerPage || DEFAULT_PAGINATION_ROW_PER_PAGE
);

export const selectUserSupportedLocale = createSelector(
  selectCurrentLocale,
  (currentLocale) =>
    currentLocale
      ? currentLocale in UserSupportedLocale
        ? currentLocale
        : currentLocale in MAP_APP_LOCALE_TO_USER_LOCALE
          ? MAP_APP_LOCALE_TO_USER_LOCALE[
              currentLocale as keyof typeof MAP_APP_LOCALE_TO_USER_LOCALE
            ]
          : currentLocale
      : undefined
);

export const selectUserDetail = createSelector(
  selectProfile,
  (state: RootState) => state,
  (profile, state) => selectUserById(state, profile?.id ?? '')
);

export const selectUserDetailsById = (usersId: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => selectUserById(state, usersId)
  );
