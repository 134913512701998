import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from '@mui/material';

import { useStyles } from './NotificationListSkeleton.styles';

type LoadingProps = {
  show: boolean;
};

function Item(_: number, index: number) {
  const { classes } = useStyles();

  return (
    <ListItem
      key={index}
      className={
        classes[
          'rt--notifications--cmp--loading--notification-list-skeleton-list-item-container'
        ]
      }
    >
      <ListItemAvatar>
        <Skeleton id={'LOADING-LIST-AVATAR-SKELETON'} variant="rounded" />
      </ListItemAvatar>
      <ListItemText
        data-testid={`CSPlatformListItemText${index}`}
        primary={
          <Skeleton
            data-testid={`LOADING-LIST-PRIMARY-TEXT-SKELETON${index}`}
            variant="rectangular"
            style={{
              animationDelay: `${index * 0.5}s`,
            }}
          />
        }
        secondary={
          <Skeleton
            data-testid={`LOADING-LIST-SECONDARY-TEXT-SKELETON${index}`}
            variant="rectangular"
            style={{
              animationDelay: `${index * 0.5}s`,
            }}
          />
        }
      />
    </ListItem>
  );
}

function NotificationListSkeleton({ show }: LoadingProps) {
  if (!show) {
    return null;
  }

  const placeholders = Array.from(new Array(3)).map(Item);

  return <List data-testid="LOADING-LIST-WRAPPER">{placeholders}</List>;
}

export default NotificationListSkeleton;
