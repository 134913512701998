import LinearProgress from '@mui/material/LinearProgress';

import CircularSpinner from '../../assets/circularSpinner.svg?react';
import useStyles from './BDSpinner.styles';

type BDSpinnerProps = {
  type?: 'linear' | 'circular';
};

const BDSpinner = ({ type = 'linear' }: BDSpinnerProps): JSX.Element => {
  const { classes } = useStyles();
  return type === 'linear' ? (
    <LinearProgress className={classes.spinner} data-testid="spinner" />
  ) : (
    <CircularSpinner className={classes.circleSpinner} />
  );
};

export default BDSpinner;
