import { DiagnosticEvent } from '~/common/models/alert.model';
import { COMMON_FORM_MESSAGES } from '~/common/utils/form/form.messages';

export const NOTIFICATION_SETTINGS_MESSAGES = {
  //Titles
  'common:setting': 'Setting',
  'common:vehicles': 'Vehicles',
  'common:user_plural': 'Users',
  'common:account': 'Account',
  'common:createNew': 'Create new',
  'common:loading': 'Loading',
  //Notification Card
  'settings:notification.card.vehicle.count': '{{totalCount}} Vehicle',
  'settings:notification.card.form.successMessage': 'Changes saved',
  'settings:notification.title': 'Notification',
  'settings:notification.card.dialog.saveChanges': 'Save changes',
  'settings:notification.card.name.addUser': 'User added',
  'settings:notification.card.name.addVehicle': 'Vehicle added',
  'settings:notification.card.name.onboardingVehicle': 'Vehicle',
  'settings:notification.card.name.removeUser': 'User removed',
  'settings:notification.card.name.accountUpdate': 'Account details changed',
  'settings:notification.card.name.profileUpdates': 'Profile updates',
  'settings:notification.card.name.defaultDiagnostics': 'Default Diagnostics',
  'common:edit': 'Edit',
  'common:activity': 'Activity',
  'settings:notification.card.dialog.delete.title': 'Delete notification',
  'settings:notification.card.dialog.create.successMessage':
    'Notification created',
  'settings:notification.card.dialog.delete.successMessage':
    'Notification deleted',
  'settings:notification.card.dialog.delete.message':
    '{{name}} will be deleted from your notification settings.',
  //Notifications Feed / Poppover
  'settings:settings': 'Settings',
  'settings:notifications.messages.noUnread': "You're all caught up!",
  'settings:notifications.title': 'Notifications',
  'settings:notifications.tabs': 'Notifications Tabs',
  'settings:notifications.allTab': 'All',
  'settings:notifications.editSettings': 'Edit settings',
  'settings:notifications.markAllRead': 'Mark all as read',
  'settings:notifications.markAsRead': 'Mark as read',
  'settings:notifications.markAsUnRead': 'Mark as unread',
  'settings:notifications.seeAll': 'See all',
  'settings:notifications.unreadTab': 'Unread ({{unreadCount}})',
  'settings:notifications.messages.noneAtAll': 'You have no notifications',
  'settings:notifications.messages.profileUpdated':
    'Account Profile has been updated.',
  'settings:notifications.messages.brakePads':
    'A critical issue with the Brake Pads in your vehicle {{alert_object_alias}} has been detected',
  'settings:notifications.messages.oilLife':
    'The oil life on vehicle {{alert_object_alias}} is {{alert_object_alias_oil_life}}%',
  'settings:notifications.messages.flatTire':
    'Flat tire detected on one or more of the tires in vehicle {{alert_object_alias}}',
  'settings:notifications.messages.lowTire':
    'Low air pressure in one or more of the tires on vehicle {{alert_object_alias}} has been detected',
  'settings:notifications.messages.lowBattery':
    'The battery in vehicle {{alert_object_alias}} is critically low',
  'settings:notifications.messages.engineAirFilter':
    'A critical issue with the Engine Air Filter in your vehicle {{alert_object_alias}} has been detected',
  'settings:notifications.messages.lowWasherFluid':
    'A critical issue with the Washer Fluid in your vehicle {{alert_object_alias}} has been detected',
  'settings:notifications.messages.antilockBrakingSystemCritical':
    'A critical issue with the Antilock Braking System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.antilockBrakingSystemNonCritical':
    'An issue with the Antilock Braking System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.airbagSystemCritical':
    'A critical issue with the Airbag System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.airbagSystemNonCritical':
    'An issue with the Airbag System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.emissionSystemCritical':
    'A critical issue with the Emission System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.emissionSystemNonCritical':
    'An issue with the Emission System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.engineAndTransmissionSystemCritical':
    'A critical issue with the Engine and Transmission System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.engineAndTransmissionSystemNonCritical':
    'An issue with the Engine and Transmission System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.lithiumIonBatterySystemCritical':
    'A critical issue with the Lithium Battery System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.lithiumIonBatterySystemNonCritical':
    'An issue with the Lithium Battery System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.electricDriveSystemCritical':
    'A critical issue with the Electric Drive Unit in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.electricDriveSystemNonCritical':
    'An issue with the Electric Drive Unit in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.onstarSystemCritical':
    'A critical issue with the Onstar System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.onstarSystemNonCritical':
    'An issue with the Onstar System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.lowBrakeFluidSystemCritical':
    'A critical issue with the Brake Fluid in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.lowBrakeFluidSystemNonCritical':
    'An issue with the Stability Control System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.stabilityControlSystemCritical':
    'A critical issue with the Brake Fluid in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.stabilityControlSystemNonCritical':
    'An issue with the Stability Control System in vehicle {{alert_object_alias}} has been detected. DTC Code: {{alert_object_fault_code}}',
  'settings:notifications.messages.addUser':
    '{{alert_object_user_first_name}} {{alert_object_user_last_name}} has been added.',
  'settings:notifications.messages.removeUser':
    '{{alert_object_user_first_name}} {{alert_object_user_last_name}} has been removed.',
  'settings:notification.card.form.updateFailedMessage':
    'Unexpected error while updating, please try again later.',
  'settings:notifications.messages.onboardVehicle':
    'Vehicle {{alert_objectid}} has been {{alert_object_event_type}}.',
  //Edit Notification Card Content
  'settings:notification.card.deliveryMethod.title': 'Delivery Method',
  'settings:notification.card.subscribers.title': 'Subscribers',
  'settings:notification.card.subscribers.vehicles': 'Subscribers and vehicles',
  'settings:notification.card.subscribers.selectedVehicles':
    'Selected vehicles ({{count}})',
  'settings:notification.card.subscribers.selectVehicles': 'Select vehicles',
  'settings:notification.card.subscribers.noSelectedVehicles':
    'No vehicles selected',
  'settings:notification.card.subscribers.noVehiclesFound': 'No vehicles found',
  'settings:notification.card.deliveryMethod.notificationFeed':
    'Notification feed',
  'settings:notification.card.diagnostics.message.unsupported':
    'Some vehicles cannot support all diagnostics above',
  'settings:notification.card.diagnostics.message.createCustomDiagnosticNotifications':
    'Create custom diagnostic notifications to track vehicle health.',
  'asset:diagnostics': 'Diagnostics',
  'settings:notification.new': 'New notification',
  'settings:notification.name': 'Notification name',
  'common:roleList.Fleet Manager_plural': 'Fleet Managers',
  'common:email': 'Email',
  'common:utilization.allVehicles': 'All Vehicles',
  'common:create': 'Create',
  'common:cancel': 'Cancel',
  'common:save': 'Save',
  'common:form.closeDialog': 'Close the dialog',
  'common:form.submitDialog': 'Submit the dialog',
  'common:placeHolder.name': 'Enter Name',
  'common:delete': 'Delete',
  diagnostics: {
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.AIRBAG_SYSTEM}`]:
      'Airbag system',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.EMISSION_SYSTEM}`]:
      'Emissions system',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.ENGINE_AND_TRANSMISSION}`]:
      'Engine and transmission',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.LITHIUM_ION_BATTERY}`]:
      'Lithium ion battery',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.STABILITY_CONTROL_SYSTEM}`]:
      'Stability control system',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.LOW_BATTERY}`]:
      'Vehicle battery',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.LOW_TIRE_PRESSURE}`]:
      'Tire pressure / sensors',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.ANTILOCK_BRAKING_SYSTEM}`]:
      'Anti-lock braking system',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.LOW_WASHER_FLUID}`]:
      'Washer fluid',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.LOW_BRAKE_FLUID}`]:
      'Brake fluid',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.FLAT_TIRE}`]:
      'Flat tire',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.OIL_LIFE}`]:
      'Oil life',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.BRAKE_PADS}`]:
      'Brake pad',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.ENGINE_AIR_FILTER}`]:
      'Engine air filter',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.ONSTAR_SYSTEM}`]:
      'OnStar system',
    [`settings:notification.card.diagnostics.type.${DiagnosticEvent.ELECTRIC_DRIVE_SYSTEM}`]:
      'Electric drive system',
  },
  errors: {
    ...COMMON_FORM_MESSAGES.errors,
    'common:errors.requiredField': 'Required Field',
    'common:errors.nameRequired': 'Name is required',
    'settings:notification.card.form.requiredDiagnosticsMessage':
      'Select a diagnostic',
    'settings:notification.card.form.requiredDeliveryMethodMessage':
      'Select a delivery method',
  },
};
