import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Profile } from '@gm-commercial/profile-model';

import BankIcon from '~/assets/icons/new/bank-icon.svg?react';
import { FeatureFlagValueType } from '~/common/models/featureFlags.model';
import {
  ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
  ACCOUNT_PROFILE_PERSONAL_PATH,
  ACCOUNT_USER_LIST_PATH,
} from '~/common/models/pages/accountPages.model';
import { RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import GearIcon from '../../../assets/icons/new/gearIcon.svg?react';
import ProfileIcon from '../../../assets/icons/new/profile.svg?react';
import UserIcon from '../../../assets/icons/new/user.svg?react';
import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';

export const getAccountNavBarConfig = ({
  messages,
  profileParams,
  findFlagScope,
}: {
  messages: typeof NAV_DRAWER_MESSAGES.accountMenu;
  profileParams: Profile['permissionsContextIds'];
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => {
  const context = {
    fleetsId: profileParams?.fleetsId || undefined,
    hubsId: profileParams?.hubsId || undefined,
    organizationsId: profileParams?.organizationsId || undefined,
  };

  return {
    title: messages['common:account'],
    homePath: '/',
    items: [
      {
        label: messages['account:profile'],
        testId: 'account-profile',
        icon: <ProfileIcon className="noFill" />,
        path: ACCOUNT_PROFILE_PERSONAL_PATH,
      },
      {
        label: messages['common:organization'],
        testId: 'account-organization',
        icon: <BankIcon className="noFill" />,
        path: makeRoutePath(
          findRoute(RouteName.CS_PLATFORM_ACCOUNT_FLEET_PROGRAMS),
          context,
          true,
          true,
          findFlagScope
        ),
        children: [
          {
            label: messages['organization:orgDetails'],
            testId: 'organization-details',
            path: makeRoutePath(
              findRoute(RouteName.CS_PLATFORM_ACCOUNT_ORG_DETAILS),
              {},
              false,
              false,
              findFlagScope
            ),
          },
          {
            label: messages['organization:fleetPrograms'],
            testId: 'fleet-programs',
            path: makeRoutePath(
              findRoute(RouteName.CS_PLATFORM_ACCOUNT_FLEET_PROGRAMS),
              context,
              true,
              true,
              findFlagScope
            ),
          },
        ],
      },
      {
        label: messages['common:users'],
        testId: 'account-user-list',
        icon: <UserIcon className="noFill" />,
        path: ACCOUNT_USER_LIST_PATH,
      },
      {
        label: messages['account:preferences'],
        testId: 'account-preferences',
        icon: <GearIcon className="noFill" />,
        path: ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
        children: [
          {
            label: messages['account:languageAndRegion'],
            testId: 'account-preferences-language-region',
            path: ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
          },
        ],
      },
    ],
  };
};
