import { createSelector } from '@reduxjs/toolkit';

import {
  DEFAULT_SESSION_CONFIG_KEY,
  SessionConfigType,
} from '~/common/constants/common.constant';
import { BDRequestType } from '~/common/models/apis/apiResponse.model';
import { ContinuationCache } from '~/common/models/common.model';
import {
  AssetCountSummary,
  Organization,
  OrganizationItem,
} from '~/common/models/organization.model';
import {
  IDLE_OPERATION_STATUS,
  makeSelectState,
} from '~/common/utils/store/selector.helper';

import { RootState } from '../../app/rootReducer';
import {
  OrganizationRequestType,
  OrgDetailSession,
  OrgListSession,
  selectOrgById,
} from './organizationsSlice';

export const selectOrganizationsState = makeSelectState('organizations');

const selectOrganizationsSessions = createSelector(
  selectOrganizationsState,
  (organizationsState) => organizationsState?.sessionConfigs
);

const selectOrganizationsTenantEntities = createSelector(
  selectOrganizationsState,
  (organizationsState) => organizationsState?.tenants.entities
);

export const makeSelectOrganizationDetailsById = (organizationId: string) =>
  createSelector(selectOrganizationsTenantEntities, (entities) =>
    Object.values(entities).filter((entity) => entity?.id === organizationId)
  );

const makeSelectOrganizationsSession = (
  viewType: SessionConfigType,
  sessionId: string
) =>
  createSelector(
    selectOrganizationsSessions,
    (config) => config?.[viewType]?.[sessionId]
  );

const makeSelectOrganizationsDetailsSessionById = (
  sessionId: string
): ((state: RootState) => Partial<OrgDetailSession> | undefined) =>
  makeSelectOrganizationsSession(SessionConfigType.DETAIL_VIEW, sessionId);

export const makeOrganizationsDetailsAssetCountSummarySelector = (
  sessionId: string
): ((state: RootState) => AssetCountSummary | undefined) =>
  createSelector(
    makeSelectOrganizationsDetailsSessionById(sessionId),
    (session) => session?.assetCountSummary
  );

export const selectOrgOperations = createSelector(
  selectOrganizationsState,
  (orgState) => orgState.operations
);

export const makeSelectOrgOperationByType = (
  requestType: BDRequestType | OrganizationRequestType
) =>
  createSelector(
    selectOrgOperations,
    (orgOperations) => orgOperations[requestType] || IDLE_OPERATION_STATUS
  );

/**
 * Utility function to create separate memoized org prop selectors at runtime
 */
export const makeSelectOrgProp = <K extends keyof Organization>(
  orgId: string,
  prop: K
): ((state: RootState) => Organization[K] | undefined) =>
  createSelector(
    (state: RootState) => selectOrgById(state, orgId),
    (organization) => organization?.[prop]
  );

export const makeSelectOrgStatus = (orgId: string) =>
  makeSelectOrgProp(orgId, 'status');
export const makeSelectOrgType = (orgId: string) =>
  makeSelectOrgProp(orgId, 'orgType');

export const makeSelectOrgCreationDate = (orgId: string) =>
  makeSelectOrgProp(orgId, 'creationDate');

export const selectFirstOrgOnboarding = makeSelectOrgStatus('firstOnboarding');

const makeSelectOrganizationsListSessionById = (
  sessionId: string
): ((state: RootState) => Partial<OrgListSession> | undefined) =>
  makeSelectOrganizationsSession(SessionConfigType.LIST_VIEW, sessionId);

export const makeOrganizationListOperationStatusSelector = () =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.operationStatus || IDLE_OPERATION_STATUS
  );
export const makeOrganizationListTotalCountSelector = () =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.count
  );
export const makeSelectOrganizationListPageCacheSelector = (): ((
  state: RootState
) => ContinuationCache<OrganizationItem[]> | undefined) =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.continuationCache || undefined
  );
export const makeOrganizationListContinuationTokenSelector = () =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.continuationToken || null
  );

export const makeOrganizationListPageSelector = () =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.page
  );
export const makeSelectSearchFilterValue = <
  K extends keyof Partial<OrgListSession>,
>(
  type: K
): ((state: RootState) => Partial<OrgListSession>[K] | undefined) =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.[type]
  );
export const makeOrganizationListisFilerOpenSelector = () =>
  createSelector(
    makeSelectOrganizationsListSessionById(DEFAULT_SESSION_CONFIG_KEY),
    (session) => session?.filterModalOpen
  );
