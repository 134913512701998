import { useTranslations } from '@brightdrop/localization-client';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { useAppSelect } from '~/app/store';
import { selectAlerts } from '~/features/alerts/alertsSlice.selectors';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';
import { TabType } from '~/routes/notifications/Notifications';

import NotificationSettings from './NotificationSettings';
import { useStyles } from './NotificationTabs.styles';

type NotificationTabsProps = {
  tabValue: string;
  handleChangeTab: (_event: React.SyntheticEvent, newValue: TabType) => void;
};

function NotificationTabs({
  tabValue,
  handleChangeTab,
}: NotificationTabsProps) {
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);
  const { classes } = useStyles();

  const [, unreadAlerts] = useAppSelect(selectAlerts);
  const unreadCount = unreadAlerts.length;

  return (
    <Box
      className={
        classes['rt--notifications--cmp--tabs--notification-tabs--box-header']
      }
    >
      <Box>
        <Typography variant="header1_medium">
          {translations['settings:notifications.title']}
        </Typography>
      </Box>
      <Box>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label={translations['settings:notifications.tabs']}
        >
          <Tab
            data-testid="notifications-tabs-view-all"
            label={
              <Typography variant="body_medium">
                {translations['settings:notifications.allTab']}
              </Typography>
            }
            value={TabType.all}
          />
          <Tab
            data-testid="notifications-tabs-view-unread"
            label={
              <Typography variant="body_medium">
                {unreadCount > 0 ? (
                  <Trans
                    i18nKey={'settings:notifications.unreadTabWithCount'}
                    values={{ count: unreadCount }}
                  />
                ) : (
                  translations['settings:notifications.unreadTab']
                )}
              </Typography>
            }
            value={TabType.unread}
          />
        </Tabs>
        <NotificationSettings />
      </Box>
    </Box>
  );
}

export default NotificationTabs;
