import { endDateTime, startDateTime } from '~/common/utils/date-time.utils';

import { AssetsState } from '../assetsSlice';
import {
  assetComplianceAdapter,
  assetEventsAdapter,
  assetLocationsAdapter,
  assetPeripheralVehicleAdapter,
  assetReportItemsAdapter,
  assetsAdapter,
  assetSearchAdapter,
  assetVehicleNotificationAdapter,
} from './assetsSlice.adapters';

export const INITIAL_ASSETS_STATE = assetsAdapter.getInitialState<AssetsState>({
  assetVehicleNotificationList:
    assetVehicleNotificationAdapter.getInitialState(),
  assetPeripheralVehicleList: assetPeripheralVehicleAdapter.getInitialState(),
  nextEPalletSerial: '',
  trackerSerials: [],
  ePalletVersions: [],
  assetEvents: assetEventsAdapter.getInitialState(),
  assetLocations: assetLocationsAdapter.getInitialState(),
  operations: {},
  sessionConfigs: {},
  startDate: startDateTime().toString(),
  endDate: endDateTime().toString(),
  pendingAssetOperation: undefined,
  selectedMarkerId: '',
  assetList: assetSearchAdapter.getInitialState(),
  assetReportItems: assetReportItemsAdapter.getInitialState(),
  assetComplianceList: assetComplianceAdapter.getInitialState(),
});
