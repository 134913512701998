import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  CONTINUATION_TOKEN_HEADER_NAME,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  DEFAULT_SESSION_CONFIG_KEY,
  SearchType,
  SessionConfigType,
} from '~/common/constants/common.constant';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { AssetReportFieldType } from '~/common/models/asset-report.model';
import {
  ContinuationCache,
  FormPayload,
  ListViewSession,
  NonNullablePick,
  OperationSession,
  PagedResult,
  PagedResultWithErrors,
  PaginationContinuationInfo,
  PaginationInfo,
  SearchCriteria,
  SeverityLevel,
  SummaryCounts,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  ApiAssetCountSummary,
  ApiOrganization,
  AssetCountSummary,
  OrgActivationStatus,
  Organization,
  OrganizationItem,
  OrganizationTenant,
  OrgDeclineForm,
  OrgFormFields,
} from '~/common/models/organization.model';
import { BDQueryCriteriaType } from '~/common/models/query.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { createQuery } from '../assets/utils/assets.utils';
import { buildOrgQueryParam } from './list/utils/orgList.utils';
import {
  mapApiResponseToOrg,
  mapApiResponseToOrganization,
  mapApiToAssetCountSummary,
  mapOrgFormToApiRequest,
  mapStatsApiResponseToSummaryCounts,
} from './mappers/organizations.mappers';

enum OrganizationsActionType {
  GET_ASSET_COUNT_SUMMARY = 'organizations/getAssetCountSummary',
  GET_ORGANIZATION_LIST = 'organizations/getOrganizationList',
  APPROVE_ORGANIZATION = 'organizations/approveOrganization',
  DECLINCE_ORGANIZATION = 'organizations/declineOrganization',
}
export interface OrgListFilterCriteria {
  statuses?: OrgActivationStatus[];
}
export const ORG_LIST_FILTER_EMPTY_STATE = {
  statuses: [],
} as OrgListFilterCriteria;
export interface OrgListSession
  extends Record<keyof Organization, string>,
    ListViewSession,
    OperationSession {
  continuationCache?: ContinuationCache<OrganizationItem[]>;
  searchCriteria: SearchCriteria | undefined;
  filterCriteria: OrgListFilterCriteria;
  filterModalOpen?: boolean;
}

export interface SearchFilterInfo {
  searchCriteria?: {
    searchType: SearchType;
    input?: string;
  };
  filterCriteria?: OrgListFilterCriteria;
}
export type OrganizationItemParams = RouteParams &
  OrgSessionConfigType &
  SearchFilterInfo;

export type OrganizationListParams = OrganizationItemParams &
  PaginationContinuationInfo &
  PaginationInfo;
export interface OrgDetailSession extends OperationSession {
  isDetailPropsOpen?: boolean;
  assetCountSummary?: AssetCountSummary;
}

/**
 * OrgSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface OrgSessionConfigType
 * @property {SessionConfigType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing
 */
export interface OrgSessionConfigType {
  [SessionConfigType.DETAIL_VIEW]?: {
    [id: string]: Partial<OrgDetailSession>;
  };
  [SessionConfigType.LIST_VIEW]?: {
    [id: string]: Partial<OrgListSession> | undefined;
  };
}

export type DeclineOrgFormPayload = FormPayload<
  Pick<OrgDeclineForm, 'reasonCode' | 'reasonNote'>,
  'organizationsId'
>;

export type AddOrgFormPayload = {
  formFields: NonNullablePick<
    OrgFormFields,
    'name' | 'address' | 'onBehalfOfEmail' | 'industry'
  > &
    Pick<OrgFormFields, 'preferredName' | 'website' | 'fan'>;
  params: null;
};
export type UpdateOrgFormPayload = FormPayload<
  NonNullablePick<
    OrgFormFields,
    'name' | 'address' | 'onBehalfOfEmail' | 'industry'
  > &
    Pick<OrgFormFields, 'primaryContact' | 'preferredName' | 'website' | 'fan'>,
  'organizationsId'
>;
export type OrgRouteParams = Required<Pick<RouteParams, 'organizationsId'>> &
  Partial<Pick<RouteParams, 'hubsId'>> & { sessionId?: string };

export type ActivateOrgPayload = FormPayload<
  {
    urls: { termsAndConditions: string; privacyPolicy: string };
    accepted: boolean;
  },
  'organizationsId'
>;

const organizationsAdapter = createEntityAdapter<Organization>({
  // redundant, but makes obvious the key used for fetching records
  selectId: (org: Organization) => org.id,
});

export enum OrganizationRequestType {
  SUBMIT_TERMS_AND_CONDITIONS = 'Accept terms and conditions',
  ORGANIZATION_TENANTS = 'Organization Tenants',
}

interface OrganizationsState {
  orgListItems: EntityState<OrganizationItem>;
  operations: {
    [key in BDRequestType | OrganizationRequestType]?: OperationStatus;
  };
  sessionConfigs: {
    [k in SessionConfigType]?: OrgSessionConfigType[k];
  };
  tenants: EntityState<OrganizationTenant>;
}

const tenantsAdapter = createEntityAdapter<OrganizationTenant>({
  selectId: (tenant: OrganizationTenant) => tenant.id,
});
const orgListAdapter = createEntityAdapter<OrganizationItem>({
  selectId: (org: OrganizationItem) => org.id,
});
const initialState = organizationsAdapter.getInitialState<OrganizationsState>({
  operations: {},
  sessionConfigs: {},
  tenants: tenantsAdapter.getInitialState(),
  orgListItems: orgListAdapter.getInitialState(),
});

const CONFIG = DEFAULT_API_CONFIG;
const ORG_DETAILS_CONFIG = {
  ...DEFAULT_API_CONFIG,
  axiosConfig: {
    ...DEFAULT_API_CONFIG.axiosConfig,
  },
};
export const getOrganizations = makeThunk(
  'organizations/fetchOrganizations',
  makeGetPayloadCreator<ApiResponse<Organization[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgTypeAPI}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown) => {
      if (
        hasApiResult(response, (result): result is ApiOrganization[] =>
          Array.isArray(result)
        )
      ) {
        return {
          ...response,
          result: response.result
            .filter((data) => data && typeof data === 'object')
            .map((data) => {
              return mapApiResponseToOrg(data);
            }),
        };
      }

      throw new BDError('Unable to parse org list response', {
        type: BDAppErrorType.VALIDATION,
        data: { response },
      });
    },
  })
);

export const getOrganizationList = makeThunk(
  OrganizationsActionType.GET_ORGANIZATION_LIST,
  makeGetPayloadCreator<
    ApiResponse<PagedResultWithErrors<OrganizationItem>>,
    OrganizationListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgListAPI}?size=10`,
    axiosOptions: ({ continuationToken }, state) => {
      const commonHeaders = addAcceptLanguageHeader(
        ORG_DETAILS_CONFIG,
        state.profile.currentLocale
      );

      return continuationToken
        ? addHeader(
            commonHeaders,
            CONTINUATION_TOKEN_HEADER_NAME,
            continuationToken
          )
        : commonHeaders;
    },
    argAdapter: ({ searchCriteria, filterCriteria }) => {
      const params = buildOrgQueryParam({
        search: searchCriteria,
        filter: filterCriteria,
      });
      return {
        requestParams: params,
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<PagedResult<OrganizationItem>>(response)) {
        const { items, total_items, continuation_token } = response.result;

        return {
          ...response,
          total_items,
          continuation_token,
          items: items.length
            ? items
                .filter((data) => data && typeof data === 'object')
                .map((data) => {
                  return data;
                })
            : [],
        };
      }

      throw new BDError('Unable to parse org list response', {
        type: BDAppErrorType.VALIDATION,
        data: { response },
      });
    },
  })
);
export const getOrganizationsTenants = makeThunk(
  'organizations/fetchOrganizationsTenants',
  makeGetPayloadCreator<ApiResponse<OrganizationTenant[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/tenants`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown) => {
      if (
        hasApiResult(response, (result): result is OrganizationTenant[] =>
          Array.isArray(result)
        )
      ) {
        return {
          ...response,
          result: response.result,
        };
      }

      throw new BDError('Unable to parse org tenants response', {
        type: BDAppErrorType.VALIDATION,
        data: { response },
      });
    },
  })
);

export const getOrgDetails = makeThunk(
  'organizations/fetchDetailsOverview',
  makeGetPayloadCreator<ApiResponse<OrganizationItem>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgListAPI}/:organizationsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToOrganization(response?.result),
    }),
  })
);
export const getOrganizationDetails = makeThunk(
  'organizations/fetchDetails',
  makeGetPayloadCreator<ApiResponse<Organization>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgTypeAPI}/:organizationsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToOrg(response?.result),
    }),
  })
);
export const getOrganizationStats = makeThunk(
  'organizations/fetchStats',
  makeGetPayloadCreator<ApiResponse<SummaryCounts>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/stats`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: response?.result
        ? mapStatsApiResponseToSummaryCounts(response?.result)
        : {},
    }),
  })
);

//TODO: Remove once API adds userCount to the Organization Stats by ID(Phase 2)
export const getOrgUserStats = makeThunk(
  'organizations/getOrgUserStats',
  makeGetPayloadCreator<ApiResponse<number>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: { organizationId: params.organizationsId || '' },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: Array.isArray(response?.result) ? response?.result.length : 0,
    }),
  })
);

//TODO: Remove once API adds assetCount to the Organization Stats by ID(Phase 2)
export const getOrgAssetStats = makeThunk(
  'organizations/getOrgAssetStats',
  makePostPayloadCreator<ApiResponse<number>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.orgTypeAPI}/views/readinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => {
      const requestParams = [params.organizationsId];
      const query = createQuery(
        BDQueryCriteriaType.IS_EQUAL,
        AssetReportFieldType.ORG_ID,
        requestParams
      );
      return {
        requestBody: { query },
      };
    },
    responseAdapter: (response: any) => ({
      ...response,
      result: Array.isArray(response?.result.items)
        ? response?.result.total_items
        : 0,
    }),
  })
);
export const addOrganization = makeThunk(
  'organizations/addNewOrganization',
  makePostPayloadCreator<ApiResponse<OrganizationItem>, AddOrgFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.enrollAPI}/admin/enroll`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestBody: mapOrgFormToApiRequest(payload),
    }),
  })
);

export const submitOrgTCs = makeThunk(
  'users/submitOrgTCs',
  makePutPayloadCreator<ApiResponse<Organization>, ActivateOrgPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/terms-and-conditions`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { organizationsId: payload.params.organizationsId },
      requestBody: payload.formFields,
    }),
  })
);
export const updateOrganization = makeThunk(
  'organizations/updateOrganization',
  makePutPayloadCreator<ApiResponse<OrganizationItem>, UpdateOrgFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.updateOrgAPI}/:organizationsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { organizationsId: payload.params.organizationsId },
      requestBody: mapOrgFormToApiRequest(payload),
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToOrganization(response?.result),
    }),
  })
);

export const declineOrganization = makeThunk(
  OrganizationsActionType.DECLINCE_ORGANIZATION,
  makePostPayloadCreator<ApiResponse, DeclineOrgFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.enrollAPI}/admin/enrollments/:organizationsId/decline`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { organizationsId: payload.params.organizationsId },
      requestBody: {
        reasonNote: payload.formFields.reasonNote,
        reasonCode: payload.formFields.reasonCode,
      },
    }),
  })
);

export const approveOrganization = makeThunk(
  OrganizationsActionType.APPROVE_ORGANIZATION,
  makePostPayloadCreator<ApiResponse, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.enrollAPI}/admin/enrollments/:organizationsId/approve`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
  })
);
export const deleteOrganization = makeThunk(
  'organizations/deleteOrganization',
  makeDeletePayloadCreator<ApiResponse, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId`,
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
  })
);

export const getAssetCountSummary = makeThunk(
  OrganizationsActionType.GET_ASSET_COUNT_SUMMARY,
  makeGetPayloadCreator<
    ApiResponse<ApiAssetCountSummary> & {
      errors?: BDError[];
    },
    OrgRouteParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetCountSummaryAPI}/views/assetCountSummary`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: {
        organizationId: params.organizationsId || '',
        hubId: params.hubsId || '',
      },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiToAssetCountSummary(response.result),
    }),
  })
);

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrgSessionConfig: (
      state,
      action: PayloadAction<OrgSessionConfigType>
    ) => {
      const mergeSelectedIndices = (_existing: string[], incoming: string[]) =>
        incoming;
      Object.keys(action.payload).forEach((key) => {
        const scope = key as SessionConfigType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.sessionConfigs = merge(
            state.sessionConfigs,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              customMerge: (key) => {
                if (key === 'statuses') return mergeSelectedIndices;
              },
            }
          );
        });
      });
    },
    resetOrganizationlListContinuationCache: (state) => {
      state.sessionConfigs = merge(state.sessionConfigs, {
        [SessionConfigType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            continuationToken: null,
            continuationCache: undefined,
          },
        },
      });
    },
  },
  extraReducers: (builder) => {
    // org list
    builder.addCase(getOrganizations.pending, (state) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      organizationsAdapter.removeMany(state, state.ids); // ensure no records when fetching
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      organizationsAdapter.upsertMany(state, action.payload.result);
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ALL,
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // org tenants
    builder.addCase(getOrganizationsTenants.pending, (state) => {
      state.operations[OrganizationRequestType.ORGANIZATION_TENANTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      tenantsAdapter.removeAll(state.tenants);
    });
    builder.addCase(getOrganizationsTenants.fulfilled, (state, action) => {
      tenantsAdapter.upsertMany(state.tenants, action.payload.result);
      state.operations[OrganizationRequestType.ORGANIZATION_TENANTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(getOrganizationsTenants.rejected, (state, action) => {
      state.operations[OrganizationRequestType.ORGANIZATION_TENANTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // New api version org details
    builder.addCase(getOrgDetails.pending, (state) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrgDetails.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      orgListAdapter.upsertOne(state.orgListItems, action.payload.result);
    });
    builder.addCase(getOrgDetails.rejected, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_BY_ID,
          },
        ],
      };
    });

    // org details
    builder.addCase(getOrganizationDetails.pending, (state) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrganizationDetails.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(getOrganizationDetails.rejected, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_BY_ID,
          },
        ],
      };
    });

    // org stats
    builder.addCase(getOrganizationStats.pending, (state) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrganizationStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            ...action.payload.result,
          },
        },
      });
    });
    builder.addCase(getOrganizationStats.rejected, (state, action) => {
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            fleets: undefined,
            hubs: undefined,
          },
        },
      });
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_STATS,
          },
        ],
      };
    });

    // org user stats
    //TODO: Remove once API adds userCount to the Organization Stats by ID(Phase 2)
    builder.addCase(getOrgUserStats.pending, (state) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrgUserStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            users: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getOrgUserStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_USER_SUMMARY,
          },
        ],
      };
    });

    //approve organization
    builder.addCase(approveOrganization.pending, (state, action) => {
      const { sessionId } = action.meta.arg;
      if (typeof sessionId === 'string' && sessionId) {
        const remoteSessionId = '' + sessionId;

        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [remoteSessionId]: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(approveOrganization.fulfilled, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (typeof sessionId === 'string' && sessionId) {
        const remoteSessionId = '' + sessionId;

        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [remoteSessionId]: {
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(approveOrganization.rejected, (state, action) => {
      const { sessionId } = action.meta.arg;
      if (typeof sessionId === 'string' && sessionId) {
        const remoteSessionId = '' + sessionId;

        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [remoteSessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });

    // org asset stats
    //TODO: Remove once API adds assetCount to the Organization Stats by ID(Phase 2)
    builder.addCase(getOrgAssetStats.pending, (state) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrgAssetStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            assets: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getOrgAssetStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ASSET_SUMMARY,
          },
        ],
      };
    });

    //add org
    builder.addCase(addOrganization.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(addOrganization.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(addOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });

    // submit org t&c
    builder.addCase(submitOrgTCs.pending, (state) => {
      state.operations[OrganizationRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(submitOrgTCs.fulfilled, (state) => {
      state.operations[OrganizationRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(submitOrgTCs.rejected, (state, action) => {
      state.operations[OrganizationRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // update org
    builder.addCase(updateOrganization.pending, (state) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      orgListAdapter.upsertOne(state.orgListItems, action.payload.result);
    });
    builder.addCase(updateOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // delete org
    builder.addCase(deleteOrganization.pending, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(deleteOrganization.fulfilled, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(deleteOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.DELETE,
          },
        ],
      };
    });
    // decline org
    builder.addCase(declineOrganization.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(declineOrganization.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(declineOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });
    // getAssetCountSummary
    builder.addCase(getAssetCountSummary.pending, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [sessionId]: {
                assetCountSummary: {},
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getAssetCountSummary.fulfilled, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [sessionId]: {
                assetCountSummary: action.payload.result,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getAssetCountSummary.rejected, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    // getOrganizationList
    builder.addCase(getOrganizationList.pending, (state) => {
      orgListAdapter.removeMany(state.orgListItems, state.ids);

      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getOrganizationList.fulfilled, (state, action) => {
      const { items, total_items } = action.payload.result;
      const { page, rowsPerPage, continuationToken } = action.meta.arg;
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.LIST_VIEW] || {};

      orgListAdapter.setAll(state.orgListItems, items);
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              count: total_items,
              page,
              rowsPerPage,
              continuationToken,
              continuationCache: {
                ...existingConfigs.continuationCache,
                [Number(page)]: {
                  data: items,
                  continuationToken:
                    action.payload.result.continuation_token ?? null,
                },
              },
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getOrganizationList.rejected, (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const { setOrgSessionConfig, resetOrganizationlListContinuationCache } =
  organizationsSlice.actions;

export const {
  selectAll: selectAllOrgs,
  selectById: selectOrgById,
  selectIds: selectOrgIds,
} = organizationsAdapter.getSelectors<RootState>(
  (state: RootState) => state.organizations
);

export const { selectAll: selectAllOrganizationTenants } =
  tenantsAdapter.getSelectors<RootState>(
    (state: RootState) => state.organizations.tenants
  );

export const organizationsReducer = organizationsSlice.reducer;

export const {
  selectAll: selectAllOrganizationList,
  selectById: selectOrgListById,
} = orgListAdapter.getSelectors<RootState>(
  (state: RootState) => state.organizations.orgListItems
);
