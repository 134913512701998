import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { BDSpinner } from '../BDSpinner';

const DEFAULT_EMPTY_VALUE = '--';

interface ProgressLinkProps extends MuiLinkProps {
  disabled?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
}

const ProgressLink = ({
  disabled,
  isEmpty,
  children,
  isLoading,
  ...props
}: ProgressLinkProps) => {
  const theme = useTheme();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if ((event.key === 'Enter' || event.key === ' ') && !disabled && !isEmpty) {
      event.preventDefault();
      (event.target as HTMLAnchorElement).click();
    }
  };

  return !isLoading ? (
    <MuiLink
      {...props}
      component={disabled || isEmpty ? 'span' : 'a'}
      tabIndex={disabled || isEmpty ? -1 : 0}
      onKeyDown={handleKeyDown}
      sx={{
        color: disabled || isEmpty ? theme.new.color.infoGrey : undefined,
        pointerEvents: disabled || isEmpty ? 'none' : undefined,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }}
    >
      {isEmpty ? DEFAULT_EMPTY_VALUE : children}
    </MuiLink>
  ) : (
    <BDSpinner />
  );
};

export default ProgressLink;
