import { Theme } from '@mui/material/styles';
import { CSSObject } from 'styled-components';

import { ButtonProps } from './Button.model';

export const generateButtonCss = (
  theme: Theme,
  props: ButtonProps
): CSSObject => {
  return {
    cursor: 'pointer',
    flex: props.flex,
    fontFamily: theme.typography.body_medium?.fontFamily,
    fontSize: theme.typography.body_medium?.fontSize,
    fontWeight: theme.typography.body_medium?.fontWeight,
    lineHeight: theme.typography.body_medium?.lineHeight,
    margin: props.margin,
    minWidth: props.minWidth,
    width: props.width,

    '&.large': {
      height: '56px',
      padding: `16px 32px`,

      '&.icon-left': {
        padding: '16px 32px 16px 28px',
      },

      '&.icon-only': {
        width: '56px',
        padding: '16px',
      },

      '&.icon-right': {
        padding: '16px 28px 16px 32px',
      },
    },

    '&.medium': {
      height: '48px',
      padding: '12px 24px',

      '&.icon-left': {
        padding: '12px 24px 12px 20px',
      },

      '&.icon-only': {
        width: '48px',
        padding: '12px',
      },

      '&.icon-right': {
        padding: '12px 20px 12px 24px',
      },
    },

    '&.small': {
      height: '40px',
      padding: '8px 20px',

      '&.icon-left': {
        padding: '8px 20px 8px 16px',
      },

      '&.icon-only': {
        width: '40px',
        padding: '10px',
      },

      '&.icon-right': {
        padding: '8px 16px 8px 20px',
      },
    },

    '&.primary': {
      backgroundColor: theme.new.color.brandPrimary,
      border: `1px solid ${theme.new.color.border}`,
      color: theme.new.color.common.white,
      fill: theme.new.color.common.white,

      '&:hover': {
        backgroundColor: theme.new.color.actionHover,
      },

      '&:focus': {
        backgroundColor: theme.new.color.brand[700],
        boxShadow: `0px 0px 0px 2px ${theme.new.color.common.white}, 0px 0px 0px 4px ${theme.new.color.brand[500]}`,
      },

      '&:active': {
        backgroundColor: theme.new.color.actionSelected,
        boxShadow: 'none',
      },
    },

    '&.danger': {
      backgroundColor: theme.new.color.errorPrimary,
      border: `1px solid ${theme.new.color.border}`,
      color: theme.new.color.common.white,
      fill: theme.new.color.common.white,

      '&:hover': {
        backgroundColor: theme.new.color.danger[700],
      },

      '&:focus': {
        backgroundColor: theme.new.color.danger[700],
        boxShadow: `0px 0px 0px 2px ${theme.new.color.common.white}, 0px 0px 0px 4px ${theme.new.color.danger[400]}`,
      },

      '&:active': {
        backgroundColor: theme.new.color.danger[800],
        boxShadow: 'none',
      },
    },

    '&.secondary': {
      backgroundColor: 'none',
      border: `1px solid ${theme.new.color.brandPrimary}`,
      color: theme.new.color.brandPrimary,
      fill: theme.new.color.brandPrimary,

      '&:hover': {
        border: `1px solid ${theme.new.color.actionHover}`,
        color: theme.new.color.actionHover,
        fill: theme.new.color.actionHover,
      },

      '&:focus': {
        border: `1px solid ${theme.new.color.actionFocus}`,
        boxShadow: `0px 0px 0px 2px ${theme.new.color.common.white}, 0px 0px 0px 4px ${theme.new.color.brand[400]}`,
        color: theme.new.color.actionFocus,
        fill: theme.new.color.actionFocus,
      },

      '&:active': {
        border: `1px solid ${theme.new.color.actionSelected}`,
        boxShadow: 'none',
        color: theme.new.color.actionSelected,
        fill: theme.new.color.actionSelected,
      },
    },

    '&.tertiary': {
      backgroundColor: 'none',
      border: 'none',
      color: theme.new.color.brandPrimary,
      fill: theme.new.color.brandPrimary,

      '&:hover': {
        color: theme.new.color.actionHover,
        fill: theme.new.color.actionHover,
      },

      '&:focus': {
        boxShadow: `0px 0px 0px 2px ${theme.new.color.common.white}, 0px 0px 0px 4px ${theme.new.color.brand[200]}`,
        color: theme.new.color.actionFocus,
        fill: theme.new.color.actionFocus,
      },

      '&:active': {
        boxShadow: 'none',
        color: theme.new.color.actionSelected,
        fill: theme.new.color.actionSelected,
      },
    },

    '&.icon-left': {
      '.MuiButton-startIcon': {
        paddingRight: 0,
        marginRight: '4px',
      },
    },

    '&.icon-only': {
      '.MuiButton-startIcon': {
        margin: 0,
      },
    },

    '&.icon-right': {
      '.MuiButton-endIcon': {
        paddingLeft: 0,
        marginLeft: '4px',
      },
    },

    '&:not(.custom-icon-colors)': {
      '.MuiButton-startIcon, .MuiButton-endIcon': {
        'svg, path': {
          fill: 'inherit !important',
        },
      },
    },

    '&.Mui-disabled': {
      backgroundColor: theme.new.color.grey[200],
      border: 'none',
      color: theme.new.color.grey[600],
      cursor: 'default',
      fill: theme.new.color.grey[600],
    },
  };
};
