import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectEffectiveContext,
  selectSelectedContext,
} from '../../features/profile/profileSlice.selectors';

type CurrentContext = {
  organizationsId?: string;
  hubsId?: string;
  fleetsId?: string;
};

/**
 * @param fleetSwitcherEnabled
 *    Temp flag controlling whether to use the new `selectedContext` state controlled by `FleetSwitcherContainer`
 *    or the legacy `effectiveContext` state controlled by `ContextFilter`. Remove per 53713.
 * @returns current session-level org context preference, either set by the user or defaulted based on user profile assignment
 */
const useCurrentContext = (
  fleetSwitcherEnabled = false
): CurrentContext | undefined => {
  const effectiveContext = useSelector(selectEffectiveContext);
  const selectedContext = useSelector(selectSelectedContext);

  /**
   * If `fleetSwitcherEnabled` flag provided, work with new `selectedContext` state controlled by `FleetSwitcherContainer`,
   * otherwise default to `effectiveContext` state controlled by legacy `ContextFilter`
   */
  const workingContext = useMemo(() => {
    return fleetSwitcherEnabled ? selectedContext : effectiveContext;
  }, [effectiveContext, selectedContext, fleetSwitcherEnabled]);

  const currentContext: CurrentContext | undefined = useMemo(() => {
    const { id, parentIds } = workingContext || {};

    if (!id) return undefined;

    const newContext: typeof currentContext = {
      organizationsId: parentIds?.[0] ?? id,
      hubsId: workingContext?.parentIds?.length === 1 ? id : parentIds?.[1],
      fleetsId: parentIds?.length === 2 ? id : undefined,
    };

    return newContext;
  }, [workingContext]);

  return currentContext;
};

export default useCurrentContext;
