import { useTranslations } from '@brightdrop/localization-client';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useHistory, useLocation } from 'react-router-dom';

import { useAppDispatch } from '~/app/store';
import CheckSquareIcon from '~/assets/icons/new/checkSquareIcon.svg?react';
import SettingsIcon from '~/assets/icons/new/settings.svg?react';
import CSPlatformSettingsMenu from '~/common/components/SettingsMenu/CSPlatformSettingsMenu';
import { FLEET_NOTIFICATIONS } from '~/common/models/pages/fleetPages.model';
import { SETTINGS_NOTIFICATIONS_VEHICLES } from '~/common/models/pages/settingsPages.model';
import {
  setPopUp,
  useUpdateAllAlertsToReadMutation,
} from '~/features/alerts/alertsSlice';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

const ROUTE_REGEX = /\/fleet\/notifications/;

const NotificationSettings = () => {
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const disableSeeAll = ROUTE_REGEX.test(location.pathname);

  const [handleMarkAllAsRead] = useUpdateAllAlertsToReadMutation();

  const getFeedNotificationSettingsMainMenuItems = () => {
    return [
      {
        text: translations['settings:notifications.markAllRead'],
        icon: (
          <CheckSquareIcon data-testid="header-notifications-feed-options-mark-all-read" />
        ),
        onClick: () => {
          handleMarkAllAsRead();
        },
      },
      {
        text: translations['settings:settings'],
        icon: (
          <SettingsIcon data-testid="header-notifications-feed-options-settings" />
        ),
        onClick: () => {
          dispatch(setPopUp(false));
          history.push(SETTINGS_NOTIFICATIONS_VEHICLES);
        },
      },
    ];
  };

  const BtnComp = disableSeeAll ? (
    <></>
  ) : (
    <Button
      onClick={() => {
        dispatch(setPopUp(false));
        history.push(FLEET_NOTIFICATIONS);
      }}
    >
      {translations['settings:notifications.seeAll']}
    </Button>
  );

  return (
    <Box>
      {BtnComp}
      <CSPlatformSettingsMenu
        data-testid="CSPlatformSettingsMenu"
        options={getFeedNotificationSettingsMainMenuItems()}
      />
    </Box>
  );
};

export default NotificationSettings;
