import accountIcon from '~/assets/icons/new/account.svg';
import vehicleIcon from '~/assets/icons/new/carIcon.svg';
import userIcon from '~/assets/icons/new/user.svg';
import { AlertEvent } from '~/common/models/alert.model';
import { NotificationType } from '~/common/models/notification.model';

export const iconMap: Record<string, string> = {
  'app-account-update': accountIcon,
  'low-washer-fluid': vehicleIcon,
  'flat-tire': vehicleIcon,
  'oil-life': vehicleIcon,
  'brake-pads': vehicleIcon,
  'airbag-system': vehicleIcon,
  'antilock-braking-system': vehicleIcon,
  'electric-drive-system': vehicleIcon,
  'emission-system': vehicleIcon,
  'engine-and-transmission': vehicleIcon,
  'lithium-ion-battery': vehicleIcon,
  'low-battery': vehicleIcon,
  'low-brake-fluid': vehicleIcon,
  'low-tire': vehicleIcon,
  'stability-control-system': vehicleIcon,
  'app-add-user': userIcon,
  'app-remove-user': userIcon,
  'app-add-vehicle': vehicleIcon,
  'app-remove-vehicle': vehicleIcon,
  'vehicle-onboarding': vehicleIcon,
  user: userIcon,
  vehicle: vehicleIcon,
};

export const EVENT_NOTIFICATION_MAPPING: {
  [key in AlertEvent]?: NotificationType;
} = {
  [AlertEvent.ADD_USER]: NotificationType.USER_ACTIVITY,
  [AlertEvent.REMOVE_USER]: NotificationType.USER_ACTIVITY,
  [AlertEvent.ACCOUNT_UPDATE]: NotificationType.ACCOUNT_ACTIVITY,
  [AlertEvent.ONBOARDING_VEHICLE]: NotificationType.VEHICLE,
};
