import { User } from '@gm-commercial/profile-model';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  ApiAddressFields,
  mapAddressToApiResponse,
  mapApiResponseToAddress,
  stripPhoneNumber,
} from '~/common/mappers/common.mappers';
import { PartialEntity, SummaryCounts } from '~/common/models/common.model';
import {
  ApiAssetCountSummary,
  AssetCountSummary,
  Industry,
  Organization,
  OrganizationItem,
} from '~/common/models/organization.model';
import { lowercaseLiteral } from '~/common/utils/common.utils';

import { AddOrgFormPayload, UpdateOrgFormPayload } from '../organizationsSlice';

type OrgApiRequestPayload = {
  name: string;
  onBehalfOfEmail: string;
  address: ApiAddressFields;
  country: string;
  industry: Industry;
  preferredName?: string;
  website?: string;
  fan?: string;
  primaryContact?: PartialEntity<User>;
};

//TODO: Add assetCount and userCount in Phase 2 per API contract
type ApiOrgStats = {
  hubCount: number;
  fleetCount: number;
};
export const mapApiResponseToOrg = <
  Result extends Partial<
    Omit<Organization, 'address' | 'status' | 'primaryContact'>
  > & {
    address: ApiAddressFields | null;
    primaryContact: PartialEntity<User> | null;
  } & Record<string, unknown>,
>(
  rawResult: Result
): Organization => {
  const mapped = {
    ...rawResult,
    email: rawResult.emailAddress || '',
    phoneNumber:
      rawResult.phoneNumber && rawResult.phoneNumber.length >= 10
        ? stripPhoneNumber(rawResult.phoneNumber)
        : DEFAULT_EMPTY_VALUE,
    address: rawResult.address
      ? mapApiResponseToAddress(rawResult.address)
      : undefined,
    status:
      typeof rawResult.status === 'string'
        ? lowercaseLiteral(rawResult.status)
        : undefined,
    primaryContact: rawResult.primaryContact || undefined,
    orgType: rawResult.type || undefined,
    orgMasterNumber: rawResult.orgMasterNumber || undefined,
  };
  return mapped as Organization;
};

export const mapApiResponseToOrganization = <
  Result extends Partial<
    Omit<OrganizationItem, 'address' | 'status' | 'primaryContact'>
  > & {
    address: ApiAddressFields | null;
    primaryContact: PartialEntity<User> | null;
  } & Record<string, unknown>,
>(
  rawResult: Result
): OrganizationItem => {
  const mapped = {
    ...rawResult,
    emailAddress: rawResult.emailAddress || '',
    address: !isEmpty(rawResult.address) ? rawResult.address : undefined,
    status: typeof rawResult.status === 'string' ? rawResult.status : undefined,
    primaryContact: rawResult.primaryContact ?? null,
  };
  return mapped as OrganizationItem;
};

export const mapOrgFormToApiRequest = (
  payload: AddOrgFormPayload | UpdateOrgFormPayload
): OrgApiRequestPayload => {
  const {
    name,
    address,
    preferredName,
    onBehalfOfEmail,
    industry,
    website,
    fan,
  } = payload.formFields;
  let result = {
    name: name.trim(),
    address: mapAddressToApiResponse(address),
    onBehalfOfEmail,
    industry,
    preferredName,
    website,
    fan,
  } as OrgApiRequestPayload;
  if (payload.params?.organizationsId && payload.formFields.primaryContact) {
    const { id, name } = payload.formFields.primaryContact;
    result = { ...result, primaryContact: { id, name } };
  }
  return result;
};

//TODO: Phase 2 will add assets and users
export const mapStatsApiResponseToSummaryCounts = (
  apiOrgStats: ApiOrgStats
): SummaryCounts =>
  ({
    ...apiOrgStats,
    hubs: apiOrgStats.hubCount,
    fleets: apiOrgStats.fleetCount,
  }) as SummaryCounts;

export const mapApiToAssetCountSummary = (
  summary: Partial<ApiAssetCountSummary>
): AssetCountSummary => {
  const { hubs } = summary;
  return {
    organizationId: summary.organizationId,
    numberOfAssets: summary.numberOfAssets,
    hubs,
  } as AssetCountSummary;
};
