import { FormState, getFormAsState } from '@brightdrop/bd-form';
import get from 'lodash/get';
import set from 'lodash/set';

import { SearchType } from '~/common/constants/common.constant';
import { mapApiResponseToAddress } from '~/common/mappers/common.mappers';
import { SearchCriteria } from '~/common/models/common.model';
import {
  DeclineReason,
  OrganizationItem,
  OrgDeclineForm,
  OrgFormFields,
} from '~/common/models/organization.model';

import { OrgListFilterCriteria } from '../../organizationsSlice';

export const getOrgFormState = (
  organization?: OrganizationItem
): FormState<OrgFormFields> =>
  getFormAsState({
    name: organization?.name || null,
    preferredName: organization?.preferredName || null,
    website: organization?.website || null,
    industry: organization?.industry || null,
    fan: organization?.fan || null,
    country: organization?.address?.country || 'United States',
    address: organization?.address
      ? mapApiResponseToAddress(organization.address)
      : null,
    onBehalfOfEmail: organization?.emailAddress || null,
    primaryContact: organization?.primaryContact || null,
  } as OrgFormFields);

export const getDeclineOrgForm = (): FormState<OrgDeclineForm> =>
  getFormAsState({
    reasonCode: DeclineReason.NOT_QUALIFIED,
    reasonNote: null,
  } as OrgDeclineForm);

const orgFieldSearchTypes: Record<string, string> = {
  [SearchType.ORG]: 'name',
  [SearchType.FAN]: 'fan',
  [SearchType.EMAIL]: 'email',
};

export const buildOrgQueryParam = ({
  search,
  filter,
}: {
  filter?: OrgListFilterCriteria;
  search?: SearchCriteria;
}) => {
  const queryParams = {};

  //Search to query
  const searchKey = orgFieldSearchTypes[search?.searchType || ''];
  if (searchKey) {
    set(queryParams, searchKey, search?.input);
  }

  // Filter to query
  if (get(filter, 'statuses.length')) {
    set(queryParams, 'statuses', filter?.statuses);
  }

  return queryParams;
};
