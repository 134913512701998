import {
  type OnServerDataMessageArgs,
  WebPubSubClient,
} from '@azure/web-pubsub-client';
import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useEffect, useRef, useState } from 'react';

import { PUB_SUB_FLAG } from '~/common/models/featureFlags.model';

import { useFetchUrl } from './useFetchUrl';

const MAX_ATTEMPTS = 5;

// cb function must be memoized otherwise we get infinite loop in useEffect
type Props = {
  cb: (event: OnServerDataMessageArgs) => void;
};

const usePubSubSocket = ({ cb }: Props) => {
  const { url } = useFetchUrl();
  const [isConnected, setIsConnection] = useState(false);
  const client = useRef<WebPubSubClient | null>(null);
  const { getFlag } = useFeatureFlags();
  const isPubSubEnabled = getFlag(PUB_SUB_FLAG);

  useEffect(() => {
    if (!url || !isPubSubEnabled) {
      return;
    }

    const handleConnection = async () => {
      try {
        // Create WebSocket client.
        client.current = new WebPubSubClient(url, {
          reconnectRetryOptions: {
            maxRetries: MAX_ATTEMPTS,
            mode: 'Exponential',
            maxRetryDelayInMs: 30000,
          },
        });
        await client.current.start();

        // Connection opened
        client.current.on('connected', () => {
          setIsConnection(true);
        });

        // Listen for messages
        client.current.on('server-message', cb);

        client.current.on('disconnected', () => {
          setIsConnection(false);
        });
      } catch (e) {
        console.error('WebPubSubClient failed:', e);
      }
    };

    handleConnection();

    return () => {
      if (client.current) {
        client.current.stop();
      }
    };
  }, [url, isPubSubEnabled, cb]);

  return { isConnected };
};

export default usePubSubSocket;
