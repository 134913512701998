import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  'rt--notifications--cmp--loading--notification-list-skeleton-list-item-container':
    {
      display: 'grid',
      gap: '8px',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'max-content 1fr',

      '& *': {
        borderRadius: '12px',
      },
      '& div:nth-of-type(1) span': {
        height: '44px',
        width: '44px',
      },
      '& div:nth-of-type(2)': {
        display: 'grid',
        gap: '8px',

        '& p': {
          width: '50%',
        },
      },
    },
}));
