import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  'rt--notifications--cmp--tabs--notification-tabs--box-header': {
    borderBottom: `1px solid ${theme.new.color.line.hairline}`,

    'div:nth-of-type(2)': {
      marginInlineEnd: '12px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));
