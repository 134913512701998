import type {
  GetAlertsForCurrentUserApiArg,
  GetAlertsForCurrentUserApiResponse,
  UpdateAlertToReadApiArg,
  UpdateAlertToReadApiResponse,
  UpdateAlertToUnReadApiArg,
  UpdateAlertToUnReadApiResponse,
} from './generated-api';
import { alertsApi } from './generated-api';

alertsApi.injectEndpoints({
  endpoints: (build) => ({
    getAlertsForCurrentUser: build.query<
      GetAlertsForCurrentUserApiResponse,
      GetAlertsForCurrentUserApiArg
    >({
      query: (queryArg) => ({
        url: `/2025-01-28/me`,
        headers: {
          'cs-continuation-token': queryArg['cs-continuation-token'],
        },
        params: { size: queryArg.size },
      }),
      providesTags: (result) => {
        if (result?.result?.items.length) {
          return [
            ...result.result.items.map(
              ({ id }) => ({ type: 'alerts-controller', id }) as const
            ),
          ];
        }
        return [{ type: 'alerts-controller', id: 'LIST' }];
      },
    }),
    updateAlertToUnRead: build.mutation<
      UpdateAlertToUnReadApiResponse,
      UpdateAlertToUnReadApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/me/${queryArg.id}/unread`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: 'alerts-controller', id }];
      },
    }),
    updateAlertToRead: build.mutation<
      UpdateAlertToReadApiResponse,
      UpdateAlertToReadApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/me/${queryArg.id}/read`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: 'alerts-controller', id }];
      },
    }),
  }),
  overrideExisting: true,
});

export { alertsApi };
export default {};
