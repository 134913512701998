import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  'rt--notifications--notifications--box-container': {
    padding: '16px',
    borderRadius: '12px',
    display: 'grid',
    gap: '8px',
    background: theme.new.color.common.white,
    gridTemplateRows: 'max-content 1fr',
  },
}));
