import { cx } from '@emotion/css';
import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import CloseIcon from '../../assets/closeIcon.svg?react';
import ErrorIcon from '../../assets/error.svg?react';
import InfoIcon from '../../assets/info.svg?react';
import WarningIcon from '../../assets/warning.svg?react';
import { IconButton } from '../IconButton';
import { AlertBannerProps, AlertType } from './AlertBanner.model';
import useStyles from './AlertBanner.styles';

// Types for the different alert variants

const AlertBanner: React.FC<AlertBannerProps> = ({
  title,
  description,
  variant,
  testId,
  onClose,
  open,
  linkHref = '',
  linkLabel,
  showCloseIcon = false,
}) => {
  const { classes } = useStyles();
  const isStringTitle = typeof title === 'string'; // Check if title is a string
  // Determine icon based on the alert type
  const renderIcon = (type: AlertType) => {
    switch (type) {
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'info':
        return <InfoIcon />;
      default:
        return null;
    }
  };

  if (!open) return null;

  return (
    <Alert
      data-testid={`${variant}-alert-banner`}
      className={cx(classes.wrapper, `${variant}Alert`, {
        withMessage: !!description,
      })}
      severity={variant}
      icon={renderIcon(variant)}
      //  close action button
      action={
        showCloseIcon ? (
          <IconButton
            data-testid={`${testId}-alert-banner-close-button`}
            size="small"
            onClick={onClose}
            icon={<CloseIcon />}
            className={cx(classes.closeIcon, {
              withMessageCloseIcon: !!description,
            })}
          ></IconButton>
        ) : null
      }
    >
      {/* Title and Learn More link */}
      <Box
        data-testid={`${testId}-alert-banner-title`}
        className={classes.alertTitle}
      >
        {isStringTitle ? (
          <Typography variant="small_body_medium"> {title}</Typography>
        ) : (
          title
        )}

        <Box className={classes.link}>
          <Link
            to={linkHref}
            data-testid={`${testId}-alert-banner-learn-more-label`}
          >
            <Typography variant="small_body_medium">{linkLabel}</Typography>
          </Link>
        </Box>
      </Box>

      {/* description below the title */}
      {description && (
        <Typography
          component={'div'}
          className={classes.alertDescription}
          variant="small_body_regular"
          data-testid={`${testId}-alert-banner-description`}
        >
          {description}
        </Typography>
      )}
    </Alert>
  );
};

export default AlertBanner;
