import { makeStyles } from 'tss-react/mui';

import envolveLogo from '../../assets/images/envolveLogo.svg';
import gmLogo from '../../assets/images/gmLogo.svg';
import { VIEW_AS_BANNER_HEIGHT } from '../header/components/ViewAsHeader/ViewAsHeader.styles';
import { HEADER_HEIGHT } from '../header/GlobalHeader.styles';

export const NAV_DRAWER_OPEN_WIDTH = 300;
export const NAV_DRAWER_CLOSED_WIDTH = 72;

const useStyles = makeStyles<{ isActing?: boolean }>()((
  theme,
  { isActing }
) => {
  const drawerBorder = `1px solid ${theme.new.color.line.hairline}`;

  return {
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.common.white,
      border: 'none',
      [theme.breakpoints.down('md')]: {
        flex: '1',
      },
      [theme.breakpoints.up('md')]: {
        borderRight: drawerBorder,
      },
      '& svg.integrationIcon , & .MuiListItem-button:hover svg.integrationIcon':
        {
          fill: 'none',
        },
    },
    logoContainer: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
      maxHeight: `${HEADER_HEIGHT}px`,
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      '& .logo': {
        backgroundImage: `url("${gmLogo}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: `${HEADER_HEIGHT}px`,
        padding: '16px 20px',
        width: `${NAV_DRAWER_CLOSED_WIDTH}px`,
        [theme.breakpoints.up('md')]: {
          marginBottom: '12px',
          '&.isOpen': {
            backgroundImage: `url("${envolveLogo}")`,
            backgroundPositionX: 'left',
            marginLeft: '20px',
            width: 'auto',
          },
        },
      },
      '& .menu': {
        position: 'absolute',
        width: '24px',
        height: '24px',
        top: '30px',
        right: '5px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '40%',
        border: `1px solid ${theme.palette.grey[300]}`,
        '& svg': {
          width: '20px',
          height: '20px',
        },
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
        '&.isOpen': {
          display: 'none',
        },
      },
      '& .toggle': {
        display: 'none',
        '&.isOpen': {
          display: 'block',
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        },
      },
    },
    titleContainer: {
      display: 'none',
      padding: '15px',
      '&.isOpen': {
        display: 'block',
      },
    },
    drawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      zIndex: 10,
      display: 'flex',
      overflow: 'hidden',
      backgroundColor: theme.palette.common.white,
      left: 'auto',
      width: '75%',
      [theme.breakpoints.up('md')]: {
        width: `${NAV_DRAWER_OPEN_WIDTH}px`,
      },
      ...(isActing && {
        top: `${VIEW_AS_BANNER_HEIGHT}px`,
        height: `calc(100% - ${VIEW_AS_BANNER_HEIGHT}px)`,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: 10,
      display: 'flex',
      maxHeight: `${HEADER_HEIGHT}px`,
      backgroundColor: theme.palette.common.white,
      width: `${NAV_DRAWER_CLOSED_WIDTH}px`,
      overflow: 'hidden',
      borderRight: 'none',
      borderBottom: drawerBorder,
      '& .MuiListItem-root': {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      [theme.breakpoints.up('md')]: {
        borderRight: drawerBorder,
        borderBottom: 'none',
        maxHeight: '100vh',
        overflow: 'visible',
      },
      ...(isActing && {
        top: `${VIEW_AS_BANNER_HEIGHT}px`,
        height: `${HEADER_HEIGHT}px`,
        [theme.breakpoints.up('md')]: {
          borderRight: drawerBorder,
          maxHeight: '100vh',
          height: `calc(100% - ${VIEW_AS_BANNER_HEIGHT}px)`,
        },
      }),
    },
    navDrawerList: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        height: '100vh',
      },
    },
    drawerToggle: {
      display: 'block',
      marginBlockStart: 'auto',
      '& .MuiBox-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '24px',
        button: {
          padding: '0',
        },
      },
      '&:not(.isOpen)': {
        transform: 'rotate(180deg)',
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      },
      '&.isOpen': {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
        },
      },
      button: {
        '&:focus-visible': {
          borderRadius: 0,
          outlineOffset: '1px',
          outline: `2px auto ${theme.new.color.brandPrimary}`,
        },
      },
    },
    list: {
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '0',
      paddingTop: '0',
      '&.viewAsHeader li:first-of-type': {
        marginTop: '50px',
      },
      '& a': {
        whiteSpace: 'break-spaces',
      },
      [theme.breakpoints.down('lg')]: {
        '& .MuiListItem-root': {
          height: '60px',
        },
      },
    },
    closeButton: {
      justifyContent: 'flex-end',
      padding: '0',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    closeIcon: {
      fontSize: '24px',
    },
    assetsIcon: {
      width: '25px',
      fill: theme.palette.text.primary,
    },
    dashboardIcon: {
      margin: '0 0 0 2px',
    },
    alertsIcon: {
      margin: '0 0 0 2px',
    },
    insightsIcon: {
      width: '25px',
    },
    inspectionsIcon: {
      '&.Mui-selected, &.Mui-selected:hover': {
        '& path': {
          fill: theme.palette.brand.main,
        },
      },
    },
    dispatchIcon: {
      width: '25px',
      fill: theme.palette.text.primary,
    },
  };
});

export default useStyles;
