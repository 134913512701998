import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  'rt--notifications--cmp--list--list-item--notification-item--list-item': {
    minHeight: '120px',
    minWidth: '500px',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',
    alignItems: 'center',

    '.MuiListItemSecondaryAction-root': {
      right: '24px',
      top: '32px',
    },
  },
  icon: {
    backgroundColor: theme.new.color.grey[100],
  },
  unRead: {
    backgroundColor: theme.new.color.brand['100'],
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.new.color.brand['600'], // Blue ellipse dot
      width: '8px',
      height: '8px',
      left: '12px',
    },
  },
}));
