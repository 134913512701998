import { keyframes } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const rotateAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const useStyles = makeStyles()((theme) => ({
  spinner: {
    height: '4px',
    width: '99%',
    borderRadius: '50px',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '642px',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    backgroundColor: theme.new.color.brandPrimary,
    '& .MuiLinearProgress-bar1Indeterminate': {
      backgroundColor: theme.new.color.grey[300],
      mixBlendMode: 'normal',
    },
    '& .MuiLinearProgress-bar2Indeterminate': {
      backgroundColor: theme.new.color.grey[300],
      mixBlendMode: 'normal',
    },
  },
  circleSpinner: {
    animation: `${rotateAnimation} 2s linear infinite`,
  },
}));

export default useStyles;
