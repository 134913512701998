import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '&.withMessage': {
      alignItems: 'flex-start',
      '& .MuiAlert-icon': {
        paddingTop: '2px',
      },
    },
    '& .withMessageCloseIcon': {
      padding: '3px 0 0 12px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'flex-start',
    '&.errorAlert': {
      backgroundColor: theme.new.color.danger[100],
      border: `1px solid ${theme.new.color.errorPrimary}`,
    },
    '&.warningAlert': {
      backgroundColor: theme.new.color.warning[100],
      border: `1px solid ${theme.new.color.warningPrimary}`,
    },
    '&.infoAlert': {
      backgroundColor: theme.new.color.brand[100],
      border: `1px solid ${theme.new.color.brandPrimary}`,
    },
    '& .MuiAlert-action': {
      padding: 0,
      margin: 0,
    },
    '& .MuiAlert-icon': {
      marginRight: '4px',
      paddingTop: 0,
    },
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  link: {
    '& a:hover': {
      textDecoration: 'none',
    },
    '& a span': {
      color: theme.new.color.textPrimary,
    },
  },
  alertDescription: {
    marginTop: '8px',
    color: theme.new.color.text.secondary,
  },
  alertTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 0 0 12px',
    '&.MuiButtonBase-root:hover,&.MuiButtonBase-root:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
